import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import './notifications.scss'
import { AppActions } from "../../redux/actions"


const NotificationCard = ({isError=false, message='Notification', createdAt}) => {
    const [classNames, setClassNames] = useState(["notificationCard", 'flex', 'justifyCenter', 'alignCenter', (isError ? 'error': 'success')])
    const dispatch = useDispatch()

    useEffect(() => {
        setTimeout(() => {
            setClassNames(c => [...classNames, 'disappear'])
            setTimeout(() => {
                dispatch(AppActions.removeNotification(createdAt))
            }, 3000)
        }, 3000)        
    }, [])

    return <div className={classNames.join(' ')}>
        {message}
    </div>
}

const Notification = () => {
    const notifications = useSelector(state => state.app.notifications)

    return <div className="notificationsWrapper">
        {notifications.map((notification, n) => <NotificationCard key={notification.createdAt.getTime()} {...notification} />)}
    </div>
}

export default Notification