import { useState, useEffect } from 'react';
import { FileUpload, Spinner } from '../../../../components';
import './index.scss';
import { AdminActions, AppActions, CommodityProductActions } from '../../../../redux/actions';
import { CPPriceTypeEnums, Icons } from '../../../../lib/constants';
import { downloadCSVFromJson, formatDate, cookieService } from '../../../../lib/helpers';
import { useSelector } from 'react-redux';
import Call from '../../../../lib/api/fetch'
import { IntervalCurveCard, Form } from '../../../../components';
import axios from 'axios';
import AEConfig from '../../../../lib/ae-config.json'


const Curves = ({ product, dispatch, canEdit, cancelEdit }) => {
    const [values, setValues] = useState(JSON.parse(JSON.stringify(product.curves || [])))

    const users = useSelector(state => state.admin.users)

    const [waiting, setWaiting] = useState(false)

    const downloadCurve = async curve => {
        const res = await Call('/commodityProducts/' + product.id + '/curves/' + curve.id)

        const fileName = [
            product.tier1 + ' ' + product.tier2 + '(' + product.productType.code + ')',
            formatDate(curve.asOf),
            CPPriceTypeEnums[curve.priceType]
        ].join('_') + '.csv'

        downloadCSVFromJson(fileName, res.data.prices.map(price => ({
            "Product": product.tier1 + ' ' + product.tier2 + '(' + product.productType.code + ')',
            "As Of": formatDate(curve.asOf),
            "Price Type": CPPriceTypeEnums[curve.priceType],
            "Date": price.date,
            "Price": price.price
        })))
    }

    const products = useSelector(state => state.commodityProducts.products)

    const curves = products.filter(p => p.id === product.id)[0].curves

    const handleUpload = async (evt) => {
        setWaiting(true)

        const formData = new FormData();
        formData.append('file', evt.target.files[0]);
        const cookie = cookieService.get("ae_app_user_jwt");

        const res = await axios.post(
            AEConfig.API_URL + '/commodityProducts/' + product.id + '/curves?fileFormat=20', 
            formData, 
            {
                'headers': {
                    'Authorization': 'Bearer ' + cookie
                }
            }
        )

        dispatch(CommodityProductActions.Products.saveProducts(
            products.map(p => {
                if (p.id === product.id){
                    p = {
                        ...p,
                        curves: [
                            ...(p.curves || []),
                            res.data.data
                        ]
                    }
                }
                return p
            })
        ))

        setWaiting(false)

        dispatch(AppActions.addNotification({message: 'Curve uploaded for ' + product.tier1 + ' ' + product.tier2}))
    }

    useEffect(() => {
        if (!product.curves) {
            setWaiting(true)
            dispatch(CommodityProductActions.Products.getProductCurves(product.id))
        }

        else setWaiting(false)

        setValues(JSON.parse(JSON.stringify(product.curves || [])))

        if (!users) dispatch(AdminActions.Users.getUsers())
    }, [product.curves, product.id, dispatch, users])

    const curveFields = [
        { name: 'priceType', title: 'Price Type' },
        { name: 'asOf', title: 'As Of' },
        { name: 'createdBy', title: 'Updated By' },
        { name: 'createdAt', title: 'Updated At' },
    ]

    const formActions = [
        {buttonType: 'secondaryButton', onClick: cancelEdit, text: 'Cancel'}
    ]

    return <div className='commodityCurveCard fullWidth'>
        {waiting
            ? <Spinner size='s' text={'Fetching curves'} />
            : <div className='cccCurves'>
                {canEdit && <>
                    <FileUpload 
                        className={'fullWidth'}
                        onChange={handleUpload}
                        label='Upload Curve'
                        value={''}
                    />
                </>}
                {(curves || []).map(curve => ({ ...curve, asOf: new Date(curve.asOf), createdAt: new Date(curve.createdAt) }))
                    // Descending sort
                    .sort((a, b) => a.asOf < b.asOf ? 1 : -1)
                    .map(curve => {
                        let creator = users.filter(u => u.id === curve.createdBy)[0] || {}
                        curve.createdBy = creator['firstName'] + ' ' + creator['lastName']
                        return curve
                    })
                    .map(value => <IntervalCurveCard
                        key={value.id}
                        curve={{
                            ...value,
                            asOf: formatDate(value.asOf),
                            createdBy: value.createdBy,
                            createdAt: formatDate(value.createdAt),
                            priceType: CPPriceTypeEnums[value.priceType]
                        }}
                        fields={curveFields}
                        actions={[
                            {
                                children: <span style={{ fontSize: '22px' }}>{Icons.DOWNLOAD}</span>,
                                onClick: async () => await downloadCurve(value)
                            }
                        ]}
                    />)}
            </div>
        }
        <Form actions={formActions}/>
    </div>
}

export default Curves
