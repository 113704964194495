import { AdminCalls } from "../../lib/api";
import { AppActions } from "../actions"
import { put, takeEvery } from "redux-saga/effects";

function* executeGetMe() { 
	yield put(AppActions.loading(true))

	const res = yield AdminCalls.getMe()

	if (res.hasErrors){
		throw new Error(res.data[0])
	}

	yield put(AppActions.saveMe(res.data))

	yield put(AppActions.loading(false))
}

export default function* AppWatcher() {
	yield takeEvery(AppActions.types.GET_ME, executeGetMe);
}

