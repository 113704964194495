import './fileUpload.scss'


const FileUpload = ({ 
    onChange=({target: { files }}) => null, 
    className,
    label='Upload File',
    value,
    ...props
}) => {
    const classNames = ['fileInputContainer']
    
    if (className) classNames.push(className)

    return <div className={classNames.join(' ')}>
        <label className='fileInputLabel'>{label}</label>
        <input 
            type='file' 
            className='fileInput'            
            onChange={onChange} 
            value={value}
            {...props}
        />
    </div>
}

export default FileUpload;
