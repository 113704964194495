import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { MarketDataPrivileges } from "../../../lib/constants"
import { useNavigate } from "react-router-dom"
import { WeatherActions } from "../../../redux/actions"
import { Icons } from "../../../lib/constants"
import { DataCard, DeleteModal, Form, Modal } from "../../../components"


const WeatherSourceForm = ({source={}, canEdit=true, cancelEdit, handleSubmit}) => {
    const [values, setValues] = useState(source)
    const [errors, setErrors] = useState({})

    const fields = [
        {
            fieldName: 'code',
            label: 'Code',
            readOnly: !!values.id,
            value: values.code,
            onChange: ({target: {value}}) => setValues({...values, code: value})
        },
        {
            fieldName: 'name',
            label: 'Name',
            readOnly: !canEdit,
            value: values.name,
            onChange: ({target: {value}}) => setValues({...values, name: value})
        }
    ]

    const onSubmit = e => {
        e.preventDefault()
        let errObj = {};

        if (!values.code || values.code.trim() === "") errObj = {...errObj, code: 'Enter valid code'}

        if (Object.keys(errObj).length > 0){
            setErrors(errObj)
            return
        }
        handleSubmit(values)
        cancelEdit()
    }

    const actions = [
        {buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit},
        {text: values.id ? 'Update' : 'Create', type: 'submit'}
    ]

    return <Form fields={fields} errors={errors} onSubmit={onSubmit} actions={actions} canEdit={canEdit}/>
}

const WeatherSourceCard = ({source, canEdit, dispatch}) => {
    const [deleteMode, setDeleteMode] = useState(false)

    const handleSubmit = values => dispatch(WeatherActions.Sources.updateWeatherSource(values))

    const dataCardactions = [{icon: Icons.DELETE, handler: () => setDeleteMode(true), canEdit: !canEdit}]

    return <>
        <DataCard canEdit={canEdit} title={source.code} subtitle={source.name} actions={dataCardactions}>
            <WeatherSourceForm source={source} canEdit={canEdit} handleSubmit={handleSubmit}/>
        </DataCard>
        <DeleteModal 
            deleteMode={deleteMode}
            setDeleteMode={setDeleteMode}
            onDelete={() => dispatch(WeatherActions.Sources.deleteWeatherSource(source.id))}
            warningMessage={
                `Delete weather source ${source.name} (${source.code})? This will delete all weather stations assigned to this source`
            }
        />
    </>
}

const Module = ({ canEdit, setCanEdit, showModal, cancelModal }) => {
    const sources = useSelector(state => state.weather.sources)
    const me = useSelector(state => state.app.me)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(!!me){
            let authorized = me.claims.filter(c => c.name === MarketDataPrivileges.VIEW_WEATHER_SOURCES).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(me.claims.filter(c => c.name === MarketDataPrivileges.CREATE_EDIT_WEATHER_SOURCES).length > 0)

                if (!sources) dispatch(WeatherActions.Sources.getWeatherSources())
            }
        }
    }, [me, setCanEdit, navigate, sources, dispatch])


    return <>
        {(sources || []).map((source, s) => <WeatherSourceCard key={s} source={source} canEdit={canEdit} dispatch={dispatch}/>)}
        <Modal title={'Add Weather Source'} show={showModal} cancel={cancelModal}>
            <WeatherSourceForm 
                cancelEdit={cancelModal} 
                handleSubmit={source => dispatch(WeatherActions.Sources.addWeatherSource(source))}
            />
        </Modal>
    </>
}

export default Module
