import './checkBox.scss';


export default function Checkbox({
    label,
    options = [],
    onChange = () => null,
    onClick = ({target :{ value }}) => null,
    readOnly,
    className,
    errorMessage,
    inline = false
}) {
    let inputClassNames = []

    if (readOnly) inputClassNames.push('readOnly')

    let optionsClassNames = ['options']

    if (inline) optionsClassNames.push('flex justifyCenter')
    else optionsClassNames.push('flex-column')

    return <div className={['checkBoxContainer', className].join(' ')}>
        <label className={'label'}>{label}</label>
        <div className={optionsClassNames.join(' ')}>
            {options.map(({id, name, checked}, o) => <div className={'option flex alignCenter'} key={o} onClick={() => {
                if (readOnly) return
                onClick({target: { value : id }})
            }}>
                <input type="radio" className={inputClassNames.join(' ')} readOnly={readOnly} checked={checked} onChange={onChange} value={checked}/>
                <label htmlFor={`options_${className}_${name}`} className={'optionLabel'}>{name}</label>
            </div>
            )}
        </div>
        {errorMessage && <p className={'error'}>{errorMessage}</p>}
    </div>
}