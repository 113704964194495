export const types = {
    GET_WEATHER_STATIONS: "GET_WEATHER_STATIONS",
    SAVE_WEATHER_STATIONS: "SAVE_WEATHER_STATIONS",
    CREATE_WEATHER_STATIONS: "CREATE_WEATHER_STATIONS",
    UPDATE_WEATHER_STATION: "UPDATE_WEATHER_STATION",
    DELETE_WEATHER_STATION: "DELETE_WEATHER_STATION",

    // UPDATE_RULES
    UPDATE_UPDATE_RULES: "UPDATE_UPDATE_RULES"
}


export const getWeatherStations = () => ({type: types.GET_WEATHER_STATIONS})

export const saveWeatherStations = payload => ({type: types.SAVE_WEATHER_STATIONS, payload})

export const addWeatherStation = payload => ({type: types.CREATE_WEATHER_STATIONS, payload})

export const updateWeatherStation = payload => ({payload, type: types.UPDATE_WEATHER_STATION})

export const deleteWeatherStation = payload => ({payload, type: types.DELETE_WEATHER_STATION})

export const updateWeatherRule = payload => ({payload, type: types.UPDATE_UPDATE_RULES})
