export const types = {
    GET_WEATHER_SOURCES: "GET_WEATHER_SOURCES",
    SAVE_WEATHER_SOURCES: "SAVE_WEATHER_SOURCES",
    CREATE_WEATHER_SOURCE: "CREATE_WEATHER_SOURCE",
    UPDATE_WEATHER_SOURCE: "UPDATE_WEATHER_SOURCE",
    DELETE_WEATHER_SOURCE: "DELETE_WEATHER_SOURCE"
}


export function getWeatherSources() {
    return {
        type: types.GET_WEATHER_SOURCES
    };
}

export function saveWeatherSources(payload){
    return {
        type: types.SAVE_WEATHER_SOURCES,
        payload
    }
}

export function addWeatherSource(payload){
    return {
        type: types.CREATE_WEATHER_SOURCE,
        payload
    }
}

export const updateWeatherSource = payload => ({ payload, type: types.UPDATE_WEATHER_SOURCE })


export function deleteWeatherSource(payload){
    return {
        type: types.DELETE_WEATHER_SOURCE,
        payload
    }
}