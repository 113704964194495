import Call from "./fetch";

export async function getUsers() {
  return await Call("/admin/users", "GET");
}

export async function getMe() {
  return await Call("/admin/me", "GET");
}

export async function updateUser(id, body){
  return await Call("/admin/users/" + id, "PUT", body)
}

export async function createUser(body){
  return await Call("/admin/users", "POST", body)
}

export async function deleteUser(id){
  return await Call("/admin/users/" + id, 'DELETE')
}

export async function getRoles(){
  return await Call("/admin/roles", "GET");
}

export async function getClaims() {
  return await Call("/admin/claims", "GET");
}

export async function updateRole(id, body){
  return await Call("/admin/roles/" + id, 'PUT', body)
}

export async function deleteRole(id){
  return await Call("/admin/roles/" + id, 'DELETE')
}

export async function createRole(body){
  return await Call("/admin/roles", "POST", body)
}
