export const types = {
    GET_PRODUCTS: "GET_PRODUCTS",
    SAVE_PRODUCTS: "SAVE_PRODUCTS",
    CREATE_PRODUCT: "CREATE_PRODUCT",
    UPDATE_PRODUCT: "UPDATE_PRODUCT",
    DELETE_PRODUCT: "DELETE_PRODUCT",

    GET_PRODUCT_UPDATE_RULES: "GET_PRODUCT_UPDATE_RULES",
    CREATE_PRODUCT_UPDATE_RULE: "CREATE_PRODUCT_UPDATE_RULE",
    UPDATE_PRODUCT_UPDATE_RULE: "UPDATE_PRODUCT_UPDATE_RULE",
    DELETE_PRODUCT_UPDATE_RULE: "DELETE_PRODUCT_UPDATE_RULE",

    GET_PRODUCT_CURVES: "GET_PRODUCT_CURVES",
    CREATE_PRODUCT_CURVES: "CREATE_PRODUCT_CURVES",
    UPDATE_PRODUCT_CURVES: "UPDATE_PRODUCT_CURVES",
    DELETE_PRODUCT_CURVES: "DELETE_PRODUCT_CURVES"
}

export const getProducts = payload => ({type: types.GET_PRODUCTS, payload})

export const saveProducts = payload => ({type: types.SAVE_PRODUCTS, payload})

export const createProduct = payload => ({type: types.CREATE_PRODUCT, payload})

export const updateProduct = payload => ({type: types.UPDATE_PRODUCT, payload})

export const deleteProduct = payload => ({type: types.DELETE_PRODUCT, payload})

export const getProductUpdateRules = productId => ({type: types.GET_PRODUCT_UPDATE_RULES, payload: productId})

export const createProductUpdateRule = payload => ({type: types.CREATE_PRODUCT_UPDATE_RULE, payload})

export const updateProductUpdateRule = payload => ({type: types.UPDATE_PRODUCT_UPDATE_RULE, payload})

export const deleteProductUpdateRule = payload => ({type: types.DELETE_PRODUCT_UPDATE_RULE, payload})

export const getProductCurves = productId => ({type: types.GET_PRODUCT_CURVES, payload: productId})

export const createProductCurve = payload => ({type: types.CREATE_PRODUCT_CURVES, payload})
