import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { 
    Icons, 
    MarketDataPrivileges, 
    weatherReadingTypes, 
    intervalTypes as intTypes, 
    intervalTypeEnums as intTypesEnums, 
    weatherReadingTypeEnums, 
    temperatureUnitsEnums
 } from "../../../lib/constants"
import { AppActions, WeatherActions } from "../../../redux/actions"
import { DataCard, DeleteModal, Form, IntervalCurveCard, Modal, SelectBox, Tabs } from "../../../components"
import "./stations.scss"
import Call from "../../../lib/api/fetch"
import { downloadCSVFromJson, formatDate } from "../../../lib/helpers"


const StationForm = ({station={}, canEdit=true, sources=[], handleSubmit, cancelEdit}) => {
    let placeholder = 'Select source';

    const [values, setValues] = useState(station)
    const [errors] = useState({})

    const handleSourceSelect = ({target: { value }}) => {
        if (value === placeholder) return
        setValues({...values, sourceId: value})
    }

    const fields = [
        {
            fieldName: 'source',
            label: 'Source',
            type: 'select',
            value: values.sourceId,
            readOnly: !!values.id,
            singleSelectOnly: true,
            placeholder: placeholder,
            options: sources.map(src => ({id: src.id, name: src.code})),
            onChange: handleSourceSelect
        },
        {
            fieldName: 'code',
            label: 'Code',
            value: values.code,
            readOnly: !!values.id,
            onChange: ({target: { value }}) => setValues({...values, code: value})
        },
        {
            fieldName: 'name',
            label: 'Name',
            value: values.name,
            readOnly: !canEdit,
            onChange: ({target: { value }}) => setValues({...values, name: value})
        },
        {
            fieldName: 'description',
            label: 'Description',
            value: values.description,
            readOnly: !canEdit,
            onChange: ({target: { value }}) => setValues({...values, description: value})
        }
    ]

    const onSubmit = e => {
        e.preventDefault();
        handleSubmit(values)
        cancelEdit()
    }

    const formActions = [
        {text: 'Cancel', buttonType: 'secondaryButton', onClick: cancelEdit},
        {text: values.id ? 'Update' : 'Create', type: 'submit'}
    ]

    return <Form fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />
}

const UpdateRules = ({station, canEdit, dispatch}) => {
    const [updateRules, setUpdateRules] = useState([
        {
            readingType: weatherReadingTypes.Historical,
            intervalType: intTypes.HOURLY,
            frequency: null,
            id: null
        },
        {
            readingType: weatherReadingTypes.Historical,
            intervalType: intTypes.DAILY,
            frequency: null,
            id: null
        },
        {
            readingType: weatherReadingTypes.Forecast,
            intervalType: intTypes.HOURLY,
            frequency: null,
            id: null
        }
    ])

    const stations = useSelector(state => state.weather.stations)

    useEffect(() => {
        if (!!station && !!station.updateRules){
            let currStationUpdateRules = stations.filter(stn => stn.id === station.id)[0].updateRules

            setUpdateRules(
                updateRules.map(ur => {
                    let match = currStationUpdateRules.filter(
                        u => (
                            u.readingType === ur.readingType && 
                            u.intervalType === ur.intervalType
                        )
                    )[0]
                    
                    if (!!match) {
                        ur.id = match.id
                        ur.frequency = match.frequency
                    }

                    else{
                        ur.id = null 
                        ur.frequency = null
                    }
    
                    return ur
                })
            )
        }
    }, [station, stations])

    const frequencyOptions = [
        {id: '', name: 'Off'},
        {id: intTypes.DAILY, name: intTypesEnums[intTypes.DAILY]},
        // {id: intTypes.HOURLY, name: intTypesEnums[intTypes.HOURLY]}
    ]

    const handleChange = async (e, ur) => {
        if (e.target.value === 'Select an option') return
        
        const freq = e.target.value.trim() === "" ? null : parseInt(e.target.value)

        const saveUpdateRule = rule => {
            dispatch(
                WeatherActions.Stations.saveWeatherStations(
                    stations.map((stn, s) => {
                        if (stn.id === station.id){
                            let exists = stn.updateRules.filter(upR => upR.id === rule.id).length > 0

                            if (!exists) stn.updateRules.push(rule)

                            else{
                                let toDelete = null

                                stn.updateRules.map((upR, idx) => {
                                    if (upR.id === rule.id){
                                        if (!rule.frequency) toDelete = rule.id
                                        else stn.updateRules[idx].frequency = rule.frequency
                                    }
                                    return upR
                                })
                                
                                if (toDelete != null) stn.updateRules = stn.updateRules.filter(
                                    r => r.id !== toDelete
                                )
                            }
                        }
                        return stn
                    })
                )
            )
        }

        // Rule does not exist
        if (!ur.id) {
            if (!freq) return

            dispatch(AppActions.loading(true))
            Call('/weather/stations/' + station.id + '/updateRules', 'POST', {
                intervalType: ur.intervalType,
                readingType: ur.readingType,
                frequency: freq
            }).then(res => saveUpdateRule(res.data))
            dispatch(AppActions.loading(false))
        }
        // rule exists
        else{
            if (freq === null){
                dispatch(AppActions.loading(true))
                Call('/weather/stations/' + station.id + '/updateRules/' + ur.id, 'DELETE').then(res => saveUpdateRule({
                    ...res.data,
                    frequency: null
                }))
                dispatch(AppActions.loading(false))
            }

            else if (freq === ur.frequency) return
        }   
    }

    return <div className="wsUpdateRuleTable fullWidth textAlignCenter">
        <table className="fullWidth">
            <thead>
                <tr>
                    <th>Reading Type</th>
                    <th>Interval Type</th>
                    <th>Frequency Type</th>
                </tr>
            </thead>
            <tbody>
                {updateRules.map((ur, udx) => <tr key={udx}>
                    <td>{weatherReadingTypeEnums[ur.readingType]}</td>
                    <td>{intTypesEnums[ur.intervalType]}</td>
                    <td className="freqInputBox">
                        <SelectBox 
                            options={frequencyOptions}
                            singleSelectOnly={true}
                            onChange={e => handleChange(e, ur)}
                            selected={[{id: ur.frequency || '', name: intTypesEnums[ur.frequency]}]}
                            disabled={!canEdit}
                        />
                    </td>
                </tr>)}
            </tbody>
        </table>
    </div>
}

const Curves = ({ station }) => {
    const downloadCurve = (curve) => Call(
        '/weather/stations/' + station.id + '/curves/' + curve.id
    ).then(res => {
        const curveStartDateTime = new Date(res.data.startDateTime)
        const curveAsOf = new Date(res.data.asOf)

        const fileName = [
            station.code, 
            intTypesEnums[curve.intervalType], 
            weatherReadingTypeEnums[curve.readingType],
            curveAsOf.toLocaleDateString()
        ].join('_') + '.csv'

        const curveData = res.data.curve.map((val, idx) => ({
            "As Of": curveAsOf.toLocaleString(),
            "Reading TS": new Date(curveStartDateTime.getTime() + idx * 60 * 60 * 1000).toLocaleString(),
            "Value": val
        }))

        downloadCSVFromJson(fileName, curveData)
    })

    const curveFields = [
        {name: 'readingType', title: 'Reading Type'},
        {name: 'intervalType', title: 'Interval Type'},
        {name: 'startDateTime', title: 'Start Date'},
        {name: 'asOf', title: 'As Of'},
        {name: 'units', title: 'Units'}        
    ]

    return <div className="wsCurvesContainer fullWidth">
        {station.curves.map((curve, cdx) => {
            let curveData = {
                ...curve,
                asOf: formatDate(new Date(curve.asOf)),
                startDateTime: formatDate(new Date(curve.startDateTime)),
                units: temperatureUnitsEnums[curve.units],
                readingType: weatherReadingTypeEnums[curve.readingType],
                intervalType: intTypesEnums[curve.intervalType]
            }
            return <IntervalCurveCard 
                key={curve.id} 
                curve={curveData} 
                fields={curveFields}
                actions={[
                    {children: <span style={{fontSize: '22px'}}>{Icons.DOWNLOAD}</span>, onClick: () => downloadCurve(curve)}
                ]}
            />
        })}
    </div>
}

const StationCard = ({station, canEdit, dispatch, sources}) => {
    const [deleteMode, setDeleteMode] = useState(false)

    const dataCardActions = [
        {icon: Icons.DELETE, canEdit: true, handler: () => setDeleteMode(true)}
    ]

    const tabOptions = [
        {
            name: "overview", 
            title: "Overview",
            element: <StationForm station={station} canEdit={canEdit} sources={sources} handleSubmit={stn => dispatch(WeatherActions.Stations.updateWeatherStation(stn))}/>
        },
        {
            name: "updateRules", 
            title: "Update Rules",
            element: <UpdateRules station={station} canEdit={canEdit} dispatch={dispatch}/>
        },
        {
            name: "curves", 
            title: "Curves",
            element: <Curves station={station}/>
        }
    ]
    
    return <>
        <DataCard 
            canEdit={canEdit}
            title={`${station.source.code}: ${station.code}`}
            subtitle={station.name + (station.description ? ': ' + station.description: '')}
            actions={dataCardActions}
        >
            <Tabs options={tabOptions} />
        </DataCard>
        <DeleteModal 
            deleteMode={deleteMode} 
            setDeleteMode={setDeleteMode} 
            onDelete={() => dispatch(WeatherActions.Stations.deleteWeatherStation(station.id))} 
            warningMessage={`Are you sure you want to delete ${station.source.code} weather station ${station.name} (${station.code})?`}
        />
    </>
}

const AddStation = ({show, cancel, dispatch, sources}) => {
    const handleSubmit = ({ sourceId, code, name, description }) => {
        dispatch(
            WeatherActions.Stations.addWeatherStation({sourceId, code, name, description})
        )
    }

    return <Modal
        title={'Add Weather Station'}
        show={show}
        cancel={cancel}
    >
        <StationForm sources={sources} cancelEdit={cancel} handleSubmit={handleSubmit}/>
    </Modal>
}

const Module = ({ canEdit, setCanEdit, showModal, cancelModal }) => {
    const sources = useSelector(state => state.weather.sources)
    const stations = useSelector(state => state.weather.stations)
    const me = useSelector(state => state.app.me)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if(!!me){
            let authorized = me.claims.filter(c => c.name === MarketDataPrivileges.VIEW_WEATHER_STATIONS).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(me.claims.filter(c => c.name === MarketDataPrivileges.CREATE_EDIT_WEATHER_STATIONS).length > 0)

                if (!sources) dispatch(WeatherActions.Sources.getWeatherSources())
                else if (!stations) dispatch(WeatherActions.Stations.getWeatherStations())
            }
        }
    }, [me, setCanEdit, navigate, sources, stations, dispatch])

    return <div className="weatherStationContainer moduleContainer">
        {(stations || [])
            .map(station => {
                station.source = sources.filter(src => src.id === station.sourceId)[0] || {}
                return station
            })
            .map((station, s) => <StationCard key={s} station={station} dispatch={dispatch} canEdit={canEdit} sources={sources}/>)}
        <AddStation show={showModal} cancel={cancelModal} sources={sources} dispatch={dispatch}/>
    </div>
}

export default Module
