import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AdminActions } from '../../redux/actions';
import { Modal, Form, DataCard, DeleteModal } from '../../components';
import { Icons, SettingsPrivileges } from '../../lib/constants';

function RoleForm({ role = {}, canEdit = true, claims, handleSubmit, cancelEdit }) {
	const [values, setValues] = useState(role);
	const [errors, setErrors] = useState({});

	const onSubmit = e => {
		e.preventDefault();
		let errObj = {};

		if (!values.name || values.name.trim() === '') errObj = { ...errObj, name: 'Enter valid role name' };

		if (Object.keys(errObj).length > 0) {
			setErrors(errObj);
			return;
		}

		handleSubmit({
			...values,
			claims: values.claims ? values.claims : [],
		});
		cancelEdit(e);
	};

	const handleClaimSelect = ({ target: { value } }) =>
		setValues({
			...values,
			claims: [...(values.claims || []), claims.filter(c => c.id === value)[0]],
		});

	const handleClaimDelete = value =>
		setValues({
			...values,
			claims: values.claims.filter(c => c.id !== value),
		});

	const fields = [
		{
			fieldName: 'name',
			label: 'Name',
			value: values.name,
			readOnly: !!values.id,
			onChange: ({ target: { value } }) => setValues({ ...values, name: value }),
		},
		{
			fieldName: 'description',
			label: 'Description',
			value: values.description,
			readOnly: !canEdit,
			onChange: ({ target: { value } }) => setValues({ ...values, description: value }),
		},
		{
			fieldName: 'claims',
			label: 'Claims',
			type: 'select',
			readOnly: !canEdit,
			value: (values.claims || []).map(c => c.id),
			options: claims.map(c => ({ id: c.id, name: c.name })),
			placeholder: 'Select a claim',
			onChange: handleClaimSelect,
			onDelete: handleClaimDelete,
		},
	];

	const formActions = [
		{ buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit },
		{ text: values.id ? 'Update' : 'Create', type: 'submit' },
	];

	return <Form fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />;
}

function RoleCard({ canEdit, role, claims, dispatch }) {
	const [deleteMode, setDeleteMode] = useState(false);

	const handleSubmit = values => {
		if (
			values.description !== role.description ||
			values.claims.length !== role.claims.length ||
			values.claims.filter(rc => claims.filter(c => c.name === rc.name).length !== 1).length > 0
		) {
			dispatch(
				AdminActions.Roles.updateRole({
					id: role.id,
					body: {
						description: values.description,
						claims: values.claims,
					},
				}),
			);
		}
	};

	const dataCardActions = [
		{
			icon: Icons.DELETE,
			handler: () => setDeleteMode(true),
			canEdit: !canEdit,
		},
	];

	return (
		<>
			<DataCard canEdit={canEdit} title={role.name} subtitle={role.description} actions={dataCardActions}>
				<RoleForm role={role} canEdit={canEdit} claims={claims} handleSubmit={handleSubmit} />
			</DataCard>
			<DeleteModal
				deleteMode={deleteMode}
				setDeleteMode={setDeleteMode}
				onDelete={() => dispatch(AdminActions.Roles.deleteRole(role.id))}
				warningMessage={'Delete role ' + role.name + '?'}
			/>
		</>
	);
}

export default function RolesModule({ showModal, cancelModal, canEdit, setCanEdit }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const me = useSelector(state => state.app.me);

	const roles = useSelector(state => state.admin.roles);
	const claims = useSelector(state => state.admin.claims);

	useEffect(() => {
		let canView =
			me.claims.filter(c => c.name === SettingsPrivileges.VIEW_ROLES || c.name === SettingsPrivileges.CREATE_EDIT_ROLES)
				.length > 0;

		if (!canView) navigate('/');
		else {
			setCanEdit(me.claims.filter(c => c.name === SettingsPrivileges.CREATE_EDIT_ROLES).length > 0);

			if (!claims) dispatch(AdminActions.Roles.getClaims());
			else if (!roles) dispatch(AdminActions.Roles.getRoles());
		}
	}, [claims, roles, dispatch, navigate, me, setCanEdit]);

	return (
		<>
			{(roles || []).map((role, r) => (
				<RoleCard key={r} canEdit={canEdit} role={role} claims={claims} dispatch={dispatch} />
			))}
			<Modal show={showModal} cancel={cancelModal} title={'Add Role'}>
				<RoleForm
					claims={claims || []}
					handleSubmit={values =>
						dispatch(
							AdminActions.Roles.createRole({
								...values,
								claims: values.claims.map(c => c.id),
							}),
						)
					}
					cancelEdit={cancelModal}
				/>
			</Modal>
		</>
	);
}
