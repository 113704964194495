import { Sources, Stations } from '../../modules/market/weather'
import ReRoute from '../../lib/helpers/reRoute'

const WeatherRoutes = {
    path: 'weather',
    element: <ReRoute reRoute='/market/weather/sources'/>,
    subRoutes: [
        {
            path: 'sources',
            component: Sources
        },
        {
            path: 'stations',
            component: Stations
        }
    ]
}

export default WeatherRoutes