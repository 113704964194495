// Router stuff
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import allRoutes from './routes'

function getRoutes(paths, routes=[], parentPath='/'){
    for (let idx in paths){
        let urlPath = parentPath + paths[idx].path

        if (!!paths[idx].component) routes.push(<Route key={idx + parentPath} path={urlPath} Component={paths[idx].component}/>)

        else if (!!paths[idx].element) routes.push(<Route key={idx + parentPath} path={urlPath} element={paths[idx].element}/>)

        if (!!paths[idx].subRoutes) getRoutes(paths[idx].subRoutes, routes, urlPath + '/')
    }

    return routes
} 

const AppRoutes = () => {
    return <BrowserRouter>
        <Routes>
			{getRoutes(allRoutes)}
		</Routes>
    </BrowserRouter>
}

export default AppRoutes
