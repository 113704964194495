import { useDispatch } from "react-redux"
import { SelectBox, Tabs } from "../../../../components"
import { useEffect, useState } from "react"
import { UtilitiesActions } from "../../../../redux/actions"

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const getRoutes = (routes, year)  => {
    const yearRoutes = []

    routes.map(route => {
        yearRoutes.push({
            id: route.id,
            routeNumber: route.routeNumber,
            dates: route.dates[year] || []
        })
        return route
    })

    yearRoutes.sort((a, b) => parseInt(a.routeNumber) > parseInt(b.routeNumber) ? 1: -1)

    return yearRoutes
}

const YearRoutes = ({routes, year}) => {
    const [dates, setDates] = useState([])
    const [yearRoutes, setYearRoutes] = useState([])

    const [tableColumns, setTableColumns] = useState([])

    const [filters, setFilters] = useState({ routeNumber: null, month: null })

    useEffect(() => {
        setYearRoutes(getRoutes(routes, year))
    }, [routes, year])

    useEffect(() => {
        let newDates = []

        MONTHS.map((month, m) => {
            let monthRoutes = {'month': month}
    
            yearRoutes.map(y => {
                monthRoutes[y.routeNumber] = y.dates[m]
                return true
            })

            newDates.push(monthRoutes)
            return true
        })

        setDates(newDates)

        setTableColumns([
            {key: 'month', title: 'Month'},
            ...yearRoutes.map(x => ({key: x.routeNumber, title: x.routeNumber}))
        ])

    }, [yearRoutes])

    const routeOptions = tableColumns.filter(f => f.key !== 'month').map(f => ({id: f.key, name: f.title}))

    const monthOptions = MONTHS.map(m => ({id: m, name: m}))

    return <div className="mrsContainer">
        <div className="tools">
            <SelectBox 
                label={'Route'} 
                options={routeOptions}
                placeholder={'All'}
                singleSelectOnly={true}
                selected={filters.routeNumber === null ? [] : routeOptions.filter(rto => rto.id === filters.routeNumber)}
                onChange={({target: { value }}) => setFilters({...filters, routeNumber: value === 'All' ? null : value})}
            />
            <SelectBox 
                label={'Month'}
                options={monthOptions}
                placeholder={'All'}
                singleSelectOnly={true}
                selected={filters.month === null ? [] : monthOptions.filter(mo => mo.id === filters.month) }
                onChange={({target: { value }}) => setFilters({...filters, month: value === 'All' ? null : value})}
            />
        </div>
        <table className="meterReadSchedules">
            <thead>
                <tr className="mrsHeader">
                    {tableColumns.filter(tc => (
                        filters.routeNumber ? tc.key === filters.routeNumber || tc.key === 'month' : true
                    )).map((tc, t) => <th key={t}>{tc.title}</th>)}
                </tr>
            </thead>
            <tbody>
                {dates.filter(month => (
                    filters.month ? month.month === filters.month : true
                )).map((month, m) => <tr key={m} className="mrsRecord">
                    {tableColumns.filter(tc => (
                        filters.routeNumber ? tc.key === filters.routeNumber || tc.key === 'month' : true
                    )).map((tc, t) => <td key={t}>{month[tc.key]}</td>)}
                </tr>)}
            </tbody>
        </table>
    </div>
}

const MeterReadSchedules = ({utility}) => {    
    const dispatch = useDispatch()

    useEffect(() => {
        if (!utility.schedules) dispatch(UtilitiesActions.getMeterReadSchedules(utility.id))
    }, [utility.schedules])

    let names = {}

    let allYears = (utility.schedules || []).map(x => Object.keys(x.dates).map(y => y).flat(1)).flat(1)

    allYears.map(y => names[y] = true)

    const options = Object.keys(names).map(year => ({
        name: year,
        title: year,
        element: <YearRoutes routes={utility.schedules} year={year} />
    }))

    if (options.length === 0) return <>Meter read schedules not found</>

    return <Tabs options={options}/>
}

export default MeterReadSchedules;
