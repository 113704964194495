export const types = {
    LOADING: "LOADING",
    IN_APP_LOADING: "IN_APP_LOADING",
    GET_ME: "GET_ME",
    SAVE_ME: "SAVE_ME",
    ADD_NOTIFICATION: "ADD_NOTIFICATION",
    REMOVE_NOTIFICATION: "REMOVE_NOTIFICATION",
    SAVE_NOTIFICATIONS: "SAVE_NOTIFICATIONS"
};

export function loading(status) {
    return {
      type: types.LOADING,
      status,
    };
  }
  
export function getMe(){
    return {
        type: types.GET_ME
    }
}

export function saveMe(payload) {
    return {
        type: types.SAVE_ME,
        payload,
    };
}

export const inAppLoading = status => ({type: types.IN_APP_LOADING, payload: status})

export const addNotification = payload => ({ type: types.ADD_NOTIFICATION, payload})

export const removeNotification = idx => ({ type: types.REMOVE_NOTIFICATION, payload: idx})

export const saveNotifications = payload => ({ type: types.SAVE_NOTIFICATIONS, payload})
