import { Page } from '../../../components'
import SourcesModule from "./sources";
import StationsModule from "./stations";

const sideBarRoutes = [
    {title: 'Sources', route: '/market/weather/sources'},
    {title: 'Stations', route: '/market/weather/stations'}
]

const prevPage = {to: '/market', text: 'Back to Market'}

export function Sources(){
    return <Page 
        pageName={'Sources'}
        Component={SourcesModule}
        sideBarRoutes={sideBarRoutes}
        addButtonObj='Source'
        prevPage={prevPage}
    />
}

export function Stations(){
    return <Page 
        pageName={'Stations'}
        Component={StationsModule}
        sideBarRoutes={sideBarRoutes}
        addButtonObj='Station'
        prevPage={prevPage}
    />
}