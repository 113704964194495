import { Users, Roles } from '../modules/settings'
import ReRoute from '../lib/helpers/reRoute'

const SettingRoutes = {
    path: 'settings',
    element: <ReRoute reRoute={'/settings/roles'} />,
    subRoutes: [
        {
            path: 'roles',
            component: Roles
        },
        {
            path: 'users',
            component: Users
        }
    ]
}

export default SettingRoutes