import { useEffect } from "react";
import Loader from "../components/layout/loading"
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import "./login.scss";

export default function Login() {

    const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0();

    const navigate = useNavigate()

    useEffect(() => {
        if (!isLoading & isAuthenticated) navigate('/')
    }, [isLoading, isAuthenticated, navigate]);

    return isLoading
        ? <Loader />
        : <>
            <div className={'loginContainer'}>
                <div className={'appLogo'}>
                    <img alt='Approved Energy Logo' src={'/images/logo.png'} className={'logoImage'}/>
                    <p>Welcome!</p>
                </div>
                <button className={'loginButton'} onClick={loginWithRedirect}>Login</button>
            </div>
        </>
    ;
}
