import Home from '../modules/home'
import Login from '../modules/login'

import MarketRoutes from './market'
import SettingRoutes from './settings'



const routes = [
    {
        path: "",
        component: Home,
    },
    {
        path: "login",
        component: Login,
    },
    SettingRoutes,
    MarketRoutes
]

export default routes