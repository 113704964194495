export const types = {
    // Roles
    GET_ROLES: "GET_ROLES",
    SAVE_ROLES: "SAVE_ROLES",
    UPDATE_ROLE: "UPDATE_ROLE",
    UPDATE_ROLE_CLAIMS: "UPDATE_ROLE_CLAIMS",
    CREATE_ROLE: "CREATE_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
  
    // Claims
    GET_CLAIMS: "GET_CLAIMS",
    SAVE_CLAIMS: "SAVE_CLAIMS"
}

// Roles
export function getRoles() {
    return {
        type: types.GET_ROLES
    };
}

export function saveRoles(payload) {
    return {
        type: types.SAVE_ROLES,
        payload,
    };
}

export function updateRole(payload){
    return {
        type: types.UPDATE_ROLE,
        payload: payload
    }
}

export function updateRoleClaims(payload){
    return {
        type: types.UPDATE_ROLE_CLAIMS,
        payload: payload
    }
}

export function createRole(payload){
    return {
        type: types.CREATE_ROLE,
        payload: payload
    }
}

export function deleteRole(payload){
    return {
        type: types.DELETE_ROLE,
        payload
    }
}

// Claims
export function getClaims(){
    return {
        type: types.GET_CLAIMS
    }
}

export function saveClaims(payload){
    return {
        type: types.SAVE_CLAIMS,
        payload
    }
}
