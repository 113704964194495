export const types = {
    GET_PRODUCT_TYPES: "GET_PRODUCT_TYPES",
    SAVE_PRODUCT_TYPES: "SAVE_PRODUCT_TYPES",
    CREATE_PRODUCT_TYPE: "CREATE_PRODUCT_TYPE",
    UPDATE_PRODUCT_TYPE: "UPDATE_PRODUCT_TYPE",
    DELETE_PRODUCT_TYPE: "DELETE_PRODUCT_TYPE"
}

export const getProductTypes = () => ({type: types.GET_PRODUCT_TYPES})

export const saveProductTypes = payload => ({type: types.SAVE_PRODUCT_TYPES, payload})

export const createProductType = payload => ({type: types.CREATE_PRODUCT_TYPE, payload})

export const updateProductType = payload => ({type: types.UPDATE_PRODUCT_TYPE, payload})

export const deleteProductType = payload => ({type: types.DELETE_PRODUCT_TYPE, payload})
