import { CommodityProductActions } from '../actions'

const initialState = {
    productTypes: null,
    products: null,
    dataSources: null
}

function reducer(state = initialState, action) {
    switch (action.type) {
        case CommodityProductActions.DataSources.types.SAVE_DATA_SOURCES:
            return {
                ...state,
                dataSources: action.payload
            }
        
        case CommodityProductActions.Products.types.SAVE_PRODUCTS:
            return {
                ...state,
                products: action.payload
            }
        
        case CommodityProductActions.ProductTypes.types.SAVE_PRODUCT_TYPES:
            return {
                ...state,
                productTypes: action.payload
            }
            
        default:
            return state
    }
}

export default reducer
