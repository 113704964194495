import { useState } from 'react'
import { Page } from '../../../components'
import UtilityModule from "./utility"
import UtilitiesModule from './module'

export function Utilities(){
    return <Page 
        pageName={'Utilities'}
        Component={UtilitiesModule}
        addButtonObj='Utility'
        prevPage={{to: '/market', text: 'Back to Market'}}
        widescreen={true}
    />
}

export function Utility() {
    const sideBarOpts = ['Overview', 'Rate Classes', 'Load Profiles', 'Meter Read Schedules'];

    const [pageName, setPageName] = useState('')
    const [tabName, setTabName] = useState(sideBarOpts[0])

    const sideBarRoutes = sideBarOpts.map(opt => ({
        title: opt, 
        onClick: () => setTabName(opt), 
        selected: tabName === opt
    }))

    return <Page 
        pageName={pageName}
        componentProps={{setPageName, pageName, tabName}}
        sideBarRoutes={sideBarRoutes}
        addButtonObj={tabName}
        prevPage={{to: '/market/utilities', text: 'Back to All Utilities'}}
        Component={UtilityModule}
    />
}