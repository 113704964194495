import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { CPCommodities, Icons, MarketDataPrivileges, CPCommoditiesEnums, CPSettlementsEnums } from "../../../lib/constants"
import { CommodityProductActions } from "../../../redux/actions"
import { useNavigate } from "react-router-dom"
import { DataCard, Form, Modal } from "../../../components"


const ProductTypeForm = ({productType={}, canEdit=true, handleSubmit, cancelEdit }) => {
    const [values, setValues] = useState(productType)

    const [errors, setErrors] = useState({})

    const commPlaceHolder = 'Select commodity'
    const settlementPlaceholder = 'Select settlement type'
    const touPlaceholder = 'Select time of use'

    const fields = [
        {
            fieldName: 'code',
            value: values.code,
            label: 'Code',
            readOnly: !!values.id,
            onChange: ({target: {value}}) => setValues({...values, code: value})
        },
        {
            fieldName: 'commodity',
            value: values.commodity,
            label: 'Commodity',
            type: 'select',
            readOnly: !!values.id,
            onChange: ({target: {value}}) => {
                if (value === commPlaceHolder) return
                setValues({...values, commodity: parseInt(value)})
            },
            options: Object.getOwnPropertyNames(CPCommoditiesEnums).map(comm => ({
                id: parseInt(comm), 
                name: CPCommoditiesEnums[comm]
            })),
            placeholder: commPlaceHolder,
            singleSelectOnly: true
        },
        {
            fieldName: 'settlement',
            value: values.settlement,
            label: 'Settlement',
            type: 'select',
            readOnly: !canEdit,
            onChange: ({target: {value}}) => {
                if (value === settlementPlaceholder) return
                setValues({...values, settlement: parseInt(value)})
            },
            options: Object.getOwnPropertyNames(CPSettlementsEnums).map(sett => ({
                id: parseInt(sett), 
                name: CPSettlementsEnums[sett]
            })),
            placeholder: settlementPlaceholder,
            singleSelectOnly: true
        },
        {
            fieldName: 'instrument',
            value: values.instrument,
            label: 'Instrument',
            readOnly: !canEdit,
            onChange: ({target: {value}}) => setValues({...values, instrument: value})
        },
        {
            fieldName: 'index',
            value: values.index,
            label: 'Index',
            readOnly: !canEdit,
            onChange: ({target: {value}}) => setValues({...values, index: value})
        },
        {
            fieldName: 'timeOfUse',
            value: values.timeOfUse,
            hidden: values.commodity !== CPCommodities.Power,
            label: 'Time of Use',
            type: 'select',
            readOnly: !canEdit,
            onChange: ({target: {value}}) => {
                if (value === touPlaceholder) return
                setValues({...values, timeOfUse: value})
            },
            options: [
                {id: '5x16', name: '5x16'},
                {id: '2x16', name: '2x16'},
                {id: '7x8', name: '7x8'},
                {id: 'Off', name: 'Off'},
            ],
            placeholder: touPlaceholder,
            singleSelectOnly: true
        },
        {
            fieldName: 'description',
            value: values.description,
            label: 'Description',
            readOnly: !canEdit,
            onChange: ({target: {value}}) => setValues({...values, description: value})
        },
    ]

    const onSubmit = e => {
        e.preventDefault();
        let errObj = {}

        if (!values.commodity) errObj = {
            ...errObj,
            commodity: 'Enter valid commodity'
        }

        if (!values.code || values.code.trim() === "") errObj = {
            ...errObj,
            code: 'Enter valid code'
        }

        if (Object.getOwnPropertyNames(errObj).length > 0) {
            setErrors(errObj)
            return
        }

        handleSubmit(values)
        
        cancelEdit(e)
    }

    const formActions = [
        { buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit},
        { text: values.id ? 'Update': 'Create', type: 'submit'}
    ]

    return <Form  fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />
}

const ProductTypeCard = ({ productType, canEdit, dispatch }) => {
    const [deleteMode, setDeleteMode] = useState(false)

    const handleSubmit = pt => dispatch(CommodityProductActions.ProductTypes.updateProductType(pt))

    return <><DataCard 
        canEdit={canEdit} 
        title={productType['code']} 
        subtitle={CPCommoditiesEnums[productType.commodity]}
        actions={[
            {icon: Icons.DELETE, handler: () => setDeleteMode(true), canEdit: !canEdit}
        ]}
    >
        <ProductTypeForm productType={productType} canEdit={canEdit} handleSubmit={handleSubmit}/>
    </DataCard>
    <Modal 
        show={deleteMode}
        cancel={() => setDeleteMode(false)}
        title={'Confirm Delete'}
        actions={[
            {
                buttonType: 'secondaryButton',
                children: 'Cancel',
                onClick: () => setDeleteMode(false),
                
            },
            {
                children: 'Confirm',
                onClick: () => {
                    dispatch(CommodityProductActions.ProductTypes.deleteProductType(productType.id))
                    setDeleteMode(false)
                }
            },
        ]}
    >
        <div className="flex justifyCenter alignCenter textAlignCenter" style={{minHeight: "inherit"}}>
            Are you sure you want to delete {CPCommoditiesEnums[productType.commodity]} product {productType.code}? This 
            will delete all commodity product records with this record type
        </div>
    </Modal>
    </>
}

export default function Module({ canEdit, setCanEdit, showModal, cancelModal, filters }){
    const productTypes = useSelector(state => state.commodityProducts.productTypes)
    const me = useSelector(state => state.app.me)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(!!me) {
            let authorized = me.claims.filter(c => c.name === MarketDataPrivileges.VIEW_COMMODITY_PRODUCTS).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(me.claims.filter(c => c.name === MarketDataPrivileges.CREATE_EDIT_COMMODITY_PRODUCTS).length > 0)

                if (!productTypes) dispatch(CommodityProductActions.ProductTypes.getProductTypes())
            }
        }
    })

    const applyFilters = pt => {
        let filteredPt = pt

        if (filters.commodity) filteredPt = filteredPt.filter(fpt => fpt.commodity === filters.commodity)
        
        return filteredPt
    }

    return <div className="productTypeContainer">
        {applyFilters(productTypes || []).map((pt, p) => <ProductTypeCard key={pt.id} productType={pt} canEdit={canEdit} dispatch={dispatch}/>)}
        <Modal title='Add Product Type' show={showModal} cancel={cancelModal}>
            <ProductTypeForm 
                handleSubmit={values => dispatch(CommodityProductActions.ProductTypes.createProductType(values))} 
                cancelEdit={cancelModal}
            />
        </Modal>
    </div>
}
