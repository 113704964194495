import { Icons } from "../../lib/constants";
import Button from "../button";
import InputBox from "../inputBox";
import '../inputBox/inputBox.scss'

const InputBoxList = ({
    values=[],
    label,
    onChange=({ value, index }) => null,
    readOnly=false,
    errorMessage,
    placeholder
}) => {
    const handleButtonClick = (e) => onChange({value: null, index: -1})

    return <div className="inputBoxListContainer">
        <div className='label'>{label}</div>
        {values.map((value, v) => <div key={v} className="flex fullWidth alignCenter">
            <InputBox 
                value={value} 
                onChange={({target: { value }}) => onChange({value, index: v})}
                readOnly={readOnly}
                placeholder={placeholder}
            />
            <div style={{'marginLeft': '5px'}} onClick={(e) => onChange({value: null, index: v})}>
                {Icons.CANCEL}
            </div>
        </div>)}
        <Button 
            onClick={handleButtonClick}
            className={'fullWidth'} 
            children={'+'} 
            buttonType="secondaryButton" 
        />
        {errorMessage && <span className="error">{errorMessage}</span>}
    </div>
}

export default InputBoxList;
