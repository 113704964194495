import { put, takeEvery, select, call } from "redux-saga/effects";
import { AdminCalls } from "../../../lib/api";
import { AppActions, AdminActions } from '../../actions'


function* executeGetUsers() {
	yield put(AppActions.loading(true))

	const res = yield AdminCalls.getUsers()

	if (res.hasErrors) throw new Error(res.data[0])

	yield put(AdminActions.Users.saveUsers(res.data))

	yield put(AppActions.loading(false))

}

function* executeUpdateUser(action) {
	yield put(AppActions.loading(true));

	const { id, firstName, lastName, role, roles, expiredAt } = action.payload

	const body = {
		firstName,
		lastName,
		role,
		expiredAt
	}

	if (!!roles) body['roles'] = roles.map(r => r.id)
	
	const res = yield call(AdminCalls.updateUser, id, body);

	if (res.hasErrors) throw new Error(res.data[0]);

	// Adjust state users
	let usersData = yield select(state => state.admin.users);

	if (!!usersData) {
		yield put(
			AdminActions.Users.saveUsers(
				usersData.map(u => {
					if (u.id === res.data.id) {
						u.firstName = res.data.firstName
						u.lastName = res.data.lastName
						u.role = res.data.role
						u.roles = res.data.roles
						u.expiredAt = res.data.expiredAt
					}
					return u
				})
			)
		);
	}

	// Adjust me obj in state
	const me = yield(select(state => state.app.me))

	if (me.id === res.data.id) {
		me.firstName = res.data.firstName
		me.lastName = res.data.lastName
		me.role = res.data.role
		me.roles = res.data.roles
		yield put(AppActions.saveMe(me))
	}

	// Pull screen from load
	yield put(AppActions.loading(false));
}

function* executeCreateUser(action) {
	yield put(AppActions.loading(true));

	const { firstName, lastName, role, roles, email } = action.payload

	const body = {
		roles: (roles || []).map(r => r.id),
		firstName,
		lastName,
		role,
		email
	}
	
	const res = yield call(AdminCalls.createUser, body);

	if (res.hasErrors) throw new Error(res.data[0]);

	// Adjust state users
	let stateUsers = yield select(state => state.admin.users);

	yield put(AdminActions.Users.saveUsers([...stateUsers, res.data]));

	// Pull screen from load
	yield put(AppActions.loading(false));
}

export default function* UsersWatcher() {
	yield takeEvery(AdminActions.Users.types.GET_USERS, executeGetUsers);
	yield takeEvery(AdminActions.Users.types.UPDATE_USER, executeUpdateUser);
	yield takeEvery(AdminActions.Users.types.CREATE_USER, executeCreateUser);
	// yield takeEvery(AdminActions.Users.types.DELETE_USER, executeDeleteUser);
}
