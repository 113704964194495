import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Icons, MarketDataPrivileges, StateEnums } from "../../../lib/constants";
import Call from "../../../lib/api/fetch";
import { AppActions, UtilitiesActions } from "../../../redux/actions";
import { Form, Modal, Table } from '../../../components'


const Utilities = ({ canEdit, setCanEdit, showModal, cancelModal }) => {
    const utilities = useSelector(state => state.utilities)
    const me = useSelector(state => state.app.me)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (!!me){
            let authorized = me.claims.filter(c => c.name === MarketDataPrivileges.VIEW_UTILITIES).length > 0

            if (!authorized) navigate('/')
                
            else{
                setCanEdit(me.claims.filter(c => c.name === MarketDataPrivileges.CREATE_EDIT_UTILITIES).length > 0)

                if (!utilities) dispatch(UtilitiesActions.getUtilities())
            }
        }
    }, [utilities, useDispatch, me, navigate, setCanEdit])

    const tableColumns = [
        {key: 'null', title: '', width: '1%'},
        {key: 'name', title: 'Name'},
        {key: 'code', title: 'Code', center: true, width: '20%'},
        {key: 'state', title: 'State', center: true, width: '10%'},
        {key: 'isGas', title: 'Gas', center: true, width: '10%'},
        {key: 'isElectric', title: 'Electric', center: true, width: '10%'}
    ]

    const [newUtility, setNewUtility] = useState({})
    const [newUtilErrors, setNewUtilErrors] = useState({})

    const createNewUtility = async e => {
        e.preventDefault()
        let errObj = {}

        if (!newUtility.code || newUtility.code.trim() === '' ) errObj = {...errObj, code: 'Enter valid code'}

        else if (utilities.filter(x => x.code === newUtility.code).length > 0) errObj = {...errObj, code: 'Code already assigned to another utility'}

        if (!newUtility.name || newUtility.name.trim() === "") errObj = { ...errObj, name: 'Enter valid name'}

        if (!newUtility.state) errObj = {...errObj, state: 'Enter valid state'}

        if (!newUtility.isGas && !newUtility.isElectricity) errObj = { ...errObj, commodities: "Select at least one commodity"}
        
        if (Object.keys(errObj).length > 0){
            setNewUtilErrors(errObj)
            return
        }

        setNewUtilErrors({})
        
        dispatch(AppActions.loading(true))

        const res = await Call('/utilities', 'POST',  newUtility);

        dispatch(UtilitiesActions.saveUtilities([...utilities, res.data]))

        cancelModal(e)

        dispatch(AppActions.loading(false))

        navigate('/market/utilities/' + res.data.id)
    }

    return <>
        <Table 
            columns={tableColumns} 
            data={(utilities || []).map(utility => ({
                ...utility,
                state: StateEnums[utility.state],
                isGas: utility.isGas ? Icons.CHECK : <></>,
                isElectric: utility.isElectricity ? Icons.CHECK : <></>
            }))}
            rowOnClick={({e, row}) => {
                e.stopPropagation();
                navigate('/market/utilities/' + row.id);
            }}
            searchFields={['name', 'code']}
            sortOptions={[
                {key: 'name', title: 'Name'},
                {key: 'code', title: 'Code'}
            ]}
            searchPlaceholder="Search utilities"
        />
        <Modal 
            show={showModal}
            cancel={cancelModal}
            title={'Add Utility'}
            actions={[
                {children: 'Cancel', buttonType: 'secondaryButton', onClick: cancelModal},
                {children: 'Create', onClick: createNewUtility}
            ]}
        >
            <Form canEdit={canEdit} values={newUtility} errors={newUtilErrors} fields={[
                {
                    label: 'Code',
                    fieldName: 'code',
                    onChange: e => setNewUtility({...newUtility, code: e.target.value})
                },
                {
                    label: 'Name',
                    fieldName: 'name',                    
                    onChange: e => setNewUtility({...newUtility, name: e.target.value})
                },
                {
                    label: 'State',
                    fieldName: 'state',
                    type: 'select',
                    singleSelectOnly: true,
                    placeholder: 'Select state',
                    options: Object.entries(StateEnums).map(s => ({id: parseInt(s[0]), name: s[1]})),
                    onChange: ({target: { value }}) => {
                        if (value === 'Select state') return
                        setNewUtility({...newUtility, state: parseInt(value)})
                    }
                },
                {
                    fieldName: 'commodities',
                    label: 'Commodities',
                    type: 'checkbox',
                    options: [
                        {name: "Gas", id: "Gas", checked: newUtility.isGas},
                        {name: "Electric", id: "Electric", checked: newUtility.isElectricity},
                    ],
                    onClick: ({ target: { value } }) => {
                        if (value === 'Gas') setNewUtility({...newUtility, isGas: !newUtility.isGas})
                        if (value === 'Electric') setNewUtility({...newUtility, isElectricity: !newUtility.isElectricity})
                    }
                },
            ]}/>
        </Modal>
    </>
}

export default Utilities;