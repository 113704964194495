import { useNavigate } from 'react-router-dom'
import { TbArrowNarrowLeft } from 'react-icons/tb'
import { GrRadial, GrRadialSelected } from 'react-icons/gr'

import './sidebar.scss'

const getPreviousPageComponent = ({to, text}, navigate) => <>
        <div className={'reroute flex alignCenter'} onClick={() => navigate(to)}>
            <TbArrowNarrowLeft className={'rerouteIcon'}/>
            <span>{text}</span>
        </div>
        <div className={'lineBreakSecondary'}></div>
    </>


function getNavigationComponent(navigation) {
    const { routes } = navigation

    return <div className={'navigation flex-column'}>{
        routes && routes.map((route, i) => 
            <div className={'navOption'} key={i} onClick={route.onClick}>
                <div className={'navOptionContent flex alignCenter'}>
                        {route.isSelected ? <GrRadialSelected /> : <GrRadial />}
                    <span>{route.title}</span>
                </div>
            </div>
            )}
    </div>
}

export default function SideBar({ prevPage, navProps, showSidebarDrawer=false, closeSidebar }) {
    const navigate = useNavigate()

    return <>
        <div className={'sidebarContainer'}>
            {!!prevPage && getPreviousPageComponent(prevPage, navigate)}
            {!!navProps && getNavigationComponent(navProps)}
        </div>
        {showSidebarDrawer && <div className={'sidebarDrawer'} onClick={closeSidebar}>
            {!!prevPage && getPreviousPageComponent(prevPage, navigate)}
            {!!navProps && getNavigationComponent(navProps)}
        </div>}
    </>
}