import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AdminActions } from '../../../redux/actions'
import { Button, DataCard, Form, Modal } from "../../../components";
import "./users.scss";
import { SettingsPrivileges } from "../../../lib/constants";
import { useNavigate } from "react-router-dom";


const userRoleLevelOptions = [
	{id: 0, name: 'Basic'},
	{id: 70, name: 'Admin'},
	{id: 100, name: 'System'},
]

function UserForm({ canEdit=true, user={}, roles=[], handleSubmit, cancelEdit, handleExpire }) {
	const [values, setValues] = useState(user)
	const [errors, setErrors] = useState({})

	const onSubmit = e => {
        e.preventDefault();
		let errObj = {};		
		if (!values.email || values.email.trim() === "") errObj = {...errObj, email: 'Enter valid email ID'}

		if (Object.keys(errObj).length > 0){
			setErrors(errObj)
			return
		}

        handleSubmit(values);
        cancelEdit(e);
    }

	const fields = [
		{
			fieldName: 'firstName',
			label: 'First Name',
			value: values.firstName,
			readOnly: !canEdit,
			onChange: ({target: { value }}) => setValues({...values, firstName: value})
		},
		{
			fieldName: 'lastName',
			label: 'Last Name',
			value: values.lastName,
			readOnly: !canEdit,
			onChange: ({target: { value }}) => setValues({...values, lastName: value})
		},
		{
			fieldName: 'email',
			label: 'Email',
			value: values.email,
			readOnly: !!values.id,
			onChange: ({target: { value }}) => setValues({...values, email: value})
		},
		{
			fieldName: 'role',
			label: 'Role Level',
			type: 'checkbox',
			value: [values.role],
			readOnly: !canEdit,
			onClick: ({target: { value }}) => setValues({...values, role: parseInt(value)}),
			options: userRoleLevelOptions.map(o => ({...o, checked: values.role === o.id}))
		},
		{
			fieldName: 'roles',
			label: 'Roles',
			value: (values.roles || []).map(r => r.id),
			type: 'select',
			placeholder: 'Select a role',
			options: roles.map(r => ({id: r.id, name: r.name})),
			readOnly: !canEdit,
			onChange: ({target: {value}}) => setValues({
				...values, 
				roles: [...(values.roles || []), roles.filter(r => r.id === value)[0]]
			}),
			onDelete: value => setValues({...values, roles: values.roles.filter(r => r.id !== value)})
		}
	]

	const formActions = [
		{text: 'Cancel', buttonType: 'secondaryButton', onClick: cancelEdit},
		{text: values.id ? 'Update' : 'Create', type: 'submit'}
	]

	if (!!values.id) formActions.push({text: 'Expire', buttonType: 'dangerButton', onClick: handleExpire})

	return <Form fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />	
}

function UserCard({ canEdit, user, roles, dispatch }) {
	const [expire, setExpire] = useState(false)

	const expiredAt = !user.expiredAt ? null : new Date(user.expiredAt)

	let userName = user.firstName + " " + user.lastName

	if (expiredAt) userName = '[EXPIRED] ' + userName

	const handleSubmit = u => {
		if (
			u.firstName !== user.firstName || 
			u.lastName !== user.lastName || 
			u.role !== user.role ||
			(u.roles.length !== user.roles.length) ||
			(u.roles.filter(ur => user.roles.filter(r => r.id === ur.id).length !== 1).length > 0)
			){
			dispatch(
				AdminActions.Users.updateUser(
					{
						id: user.id,
						firstName: u.firstName,
						lastName: u.lastName,
						role: u.role,
						roles: u.roles
					}
				)
			)
		}
	}

	const handleExpire = e => {
		if (!user.expiredAt) dispatch(AdminActions.Users.updateUser({id: user.id, expiredAt: new Date()}))
	}

	const handleReactivate = e => {
		if (!!user.expiredAt) dispatch(AdminActions.Users.updateUser({id: user.id, expiredAt: null}))
	}

	return <>
		<DataCard canEdit={canEdit} title={userName} subtitle={user.email}>
			{expiredAt === null ?
				<UserForm canEdit={canEdit} user={user} roles={roles} handleSubmit={handleSubmit} handleExpire={() => setExpire(true)} /> 
				: <div className="userReactivation">
					<div className="userDeactivationNotice">User was deactivated as of {expiredAt.toLocaleDateString()}</div>
					<div className="reactiveButton">
						<Button onClick={handleReactivate}>Active User</Button>
					</div>
				</div>}
		</DataCard>
		<Modal 
			title='Confirm expire'
			show={expire}
			cancel={() => setExpire(false)}
			actions={
				[
					{
						children: 'Cancel',
						buttonType: 'secondaryButton',
						onClick: () => setExpire(false)
					},
					{
						children: 'Confirm',
						onClick: handleExpire
					}
				]
			}
		>
			Are you sure you want to expire {userName}?
		</Modal>
	</>
}

export default function UsersModule({ showModal, cancelModal, canEdit, setCanEdit }) {
	const roles = useSelector(state => state.admin.roles);
	const users = useSelector(state => state.admin.users);
	const me = useSelector(state => state.app.me);

	const dispatch = useDispatch()
	const navigate = useNavigate()

	useEffect(() => {
		let canView = me.claims.filter(c => c.name === SettingsPrivileges.VIEW_USERS || c.name === SettingsPrivileges.CREATE_EDIT_USERS).length > 0

		if (!canView) navigate('/')

		else{
			setCanEdit(me.claims.filter(c => c.name === SettingsPrivileges.CREATE_EDIT_USERS).length > 0)

			if (!roles) dispatch(AdminActions.Roles.getRoles())

			else if (!users) dispatch(AdminActions.Users.getUsers())
		}
	}, [roles, users, dispatch, me, navigate, setCanEdit])

	return <>
		{(users || []).map((user, u) => <UserCard key={u} user={user} roles={roles} canEdit={canEdit} dispatch={dispatch}/>)}
		<Modal show={showModal} cancel={cancelModal} title={'Add User'}>
			<UserForm 				
				roles={roles}
				handleSubmit={values => dispatch(AdminActions.Users.createUser(values))}
				cancelEdit={cancelModal}
			/>
		</Modal>
	</>
}
