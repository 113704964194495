import { useSelector, useDispatch } from "react-redux"
import { useLocation, useNavigate  } from "react-router-dom"
import { useEffect, useState } from "react"
import { MarketDataPrivileges, StateEnums } from "../../../../lib/constants"
import { UtilitiesActions } from "../../../../redux/actions"
import { Form, DeleteModal } from "../../../../components"
import LoadProfiles from "./loadProfiles"
import MeterReadSchedules from "./schedules"
import RateClass from "./rateClass"



const EntityIdentifiersEnums = {
    20: 'Utility DUNS Number',
    21: 'Utility DUNS Plus Four Number'
}

const UtilityOverview = ({utility = {}, canEdit, dispatch, navigate }) => {
    const [values, setValues] = useState(JSON.parse(JSON.stringify(utility)))
    const [errors, setErrors] = useState({})
    const [deleteMode, setDeleteMode] = useState(false)

    const handleEntityIdentifierChange = ({e: {target: {value}}, type}) => {
        let exists = values.entityIdentifiers.filter(idf => idf.type === type).length > 0

        if (value.trim() === "" || !value) value = null

        if (!value){
            if (exists) setValues({...values, entityIdentifiers: values.entityIdentifiers.filter(idf => idf.type !== type)})
        }

        else if (exists) setValues({
            ...values,
            entityIdentifiers: values.entityIdentifiers.map(idf => {
                if (idf.type === type) idf.value = value
                return idf
            })
        })

        else setValues({...values, entityIdentifiers: [...values.entityIdentifiers, {type, value}]})
    }

    const handleChange = ({e, fieldName, operation=null, ...args}) => {
        const { target: { value }} = e

        if (!!operation) operation({value, fieldName, ...args});
        else setValues({...values, [fieldName]: value});

        let errObj = {...errors}
        delete errObj[fieldName]
        setErrors(errObj)
    }

    const fields = [
        {
            fieldName: 'code',
            value: values.code,
            label: 'Code',
            readOnly: !!values.id
        },
        {
            fieldName: 'name',
            value: values.name,
            label: 'Name',
            readOnly: canEdit,
            onChange: e => handleChange({e, fieldName: 'name'})
        },
        {
            fieldName: 'state',
            label: 'State',
            type: 'select',
            singleSelectOnly: true,
            placeholder: 'Select state',
            options: Object.entries(StateEnums).map(s => ({id: parseInt(s[0]), name: s[1]})),
            onChange: (e) => handleChange({e, fieldName: 'state', operation: ({value}) => {
                if (value === 'Select state') return
                setValues({...values, state: parseInt(value)})
            }}),
            readOnly: canEdit
        },
        {
            fieldName: 'commodities',
            label: 'Commodities',
            type: 'checkbox',
            options: [
                {name: "Gas", id: "Gas", checked: values.isGas},
                {name: "Electric", id: "Electric", checked: values.isElectricity},
            ],
            onClick: e => handleChange({e, fieldName: 'commodities', operation: ({ value }) => {
                if (value === 'Gas') setValues({...values, isGas: !values.isGas})
                if (value === 'Electric') setValues({...values, isElectricity: !values.isElectricity})
            }}),
            readOnly: canEdit
        },
        ...Object.keys(EntityIdentifiersEnums).map(idf => {
            let value = (values.entityIdentifiers || []).filter(rec => rec.type === parseInt(idf))[0]
            
            value = value ? value.value : ''

            return {
                fieldName: EntityIdentifiersEnums[idf],
                value: value,
                label: EntityIdentifiersEnums[idf],
                readOnly: canEdit,
                onChange: e => handleEntityIdentifierChange({e, type: parseInt(idf)})
            }
        })
    ]

    const onSubmit = e => {
        e.preventDefault()

        let errorObj = {}

        if (values.name.trim() === "" || !values.name) errorObj = {...errorObj, name: 'Name cannot be empty'}

        if (!values.isGas && !values.isElectricity) errorObj = {...errorObj, commodities: 'Select a valid commodity'}

        if (Object.keys(errorObj).length > 0) {
            setErrors(errorObj)
            return
        }

        dispatch(UtilitiesActions.updateUtility({
            ...values,
            entityIdentifiers: values.entityIdentifiers.filter(idf => idf.value.trim() !== '' || !!idf.value)
        }))
    }

    const actions = [{text: 'Delete', buttonType: 'dangerButton', onClick: () => setDeleteMode(true)}]

    if (JSON.stringify(values) !== JSON.stringify(utility)) actions.push({text: 'Update', type: 'submit'})
    
    return <>
        <Form fields={fields} values={values} errors={errors} actions={actions} onSubmit={onSubmit}/>
        <DeleteModal 
            deleteMode={deleteMode} 
            setDeleteMode={setDeleteMode} 
            title="Delete Utility"
            warningMessage={"Delete utility " + utility.code + ": " + utility.name + '?'}
            onDelete={e => {
                dispatch(UtilitiesActions.deleteUtility(utility.id))
                setDeleteMode(false)
                navigate('/market/utilities')
            }}
        />
    </>
}

const Utility = ({ tabName, pageName, setPageName, canEdit, setCanEdit, showModal, cancelModal}) => {
    const location = useLocation()
    const utilityId = location.pathname.split('/')[3]

    const utilities = useSelector(state => state.utilities)
    const me = useSelector(state => state.app.me)

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const utility = (utilities || []).filter(s => s.id === utilityId)[0]

    useEffect(() => {
        if (!!me){
            let authorized = me.claims.filter(c => c.name === MarketDataPrivileges.VIEW_UTILITIES).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(
                    tabName !== 'Overview' && 
                    me.claims.filter(c => c.name === MarketDataPrivileges.CREATE_EDIT_UTILITIES).length > 0
                )
    
                if (!utilities) dispatch(UtilitiesActions.getUtilities())
    
                if (utility && pageName !== utility.name) setPageName(utility['name'])
            }
        }
    }, [utilities, pageName, me, dispatch, navigate, setCanEdit, utility, setPageName, tabName])

    if (!utility) return <>Data not found</>

    switch (tabName){
        case 'Rate Classes':
            return <RateClass utility={utility} canEdit={canEdit} showModal={showModal} cancelModal={cancelModal} />

        case 'Load Profiles':
            return <LoadProfiles utility={utility} canEdit={canEdit} showModal={showModal} cancelModal={cancelModal}/>

        case 'Meter Read Schedules':
            return <MeterReadSchedules utility={utility} canEdit={canEdit} showModal={showModal} cancelModal={cancelModal}/>

        default:
            return <UtilityOverview utility={utility} canEdit={canEdit} dispatch={dispatch} navigate={navigate}/>
    }
}

export default Utility;
