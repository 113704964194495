export const types = {
    // Users
    UPDATE_USER: "UPDATE_USER",
    UPDATE_USER_ROLES: "UPDATE_USER_ROLES",
    DELETE_USER: "DELETE_USER",
    CREATE_USER: "CREATE_USER",
    GET_USERS: "GET_USERS",
    SAVE_USERS: "SAVE_USERS",
}
// Users
export function getUsers() {
    return {
        type: types.GET_USERS
    };
}

export function saveUsers(payload) {
    return {
        type: types.SAVE_USERS,
        payload,
    };
}

export function updateUser(payload) {
    return {
        type: types.UPDATE_USER,
        payload: payload
    }
}

export function updateUserRoles(payload) {
    return {
        type: types.UPDATE_USER_ROLES,
        payload: payload
    }
}

export function createUser(payload) {
    return {
        type: types.CREATE_USER,
        payload: payload
    }
}

export function deleteUser(payload) {
    return {
        type: types.DELETE_USER,
        payload
    }
}
