import { useState } from 'react';
import { Page } from '../../../components'
import ProductTypesModule from "./productTypes";
import ProductsModule from './products'
import DataSourceModule from './dataSources'
import { CPCommoditiesEnums, CPCommodities } from '../../../lib/constants';
import { useSelector } from 'react-redux';


const sideBarRoutes = [
    {title: 'Products', route: '/market/commodityProducts'},
    {title: 'Product Types', route: '/market/commodityProductTypes'},
    {title: 'Data Sources', route: '/market/commodityProducts/dataSources'}
]

const prevPage = {to: '/market', text: 'Back to Market'}

export function ProductTypes(){
    const [filters, setFilters] = useState({ commodity: null })
    
    const filterFields = [
        {
            type: 'select',
            label: 'Commodity',
            placeholder: 'All',
            singleSelectOnly: true,
            options: [
                {id: CPCommodities.NaturalGas, name: 'Natural Gas'},
                {id: CPCommodities.Power, name: 'Power'},
                {id: CPCommodities.Capacity, name: 'Capacity'},
                {id: CPCommodities.Environment, name: 'Environment'}
            ],
            onChange: ({target: {value}}) => {
                if (value === 'All') setFilters({...filters, commodity: null})
                else setFilters({...filters, commodity: parseInt(value)})
            },
            value: filters.commodity
        }
    ]

    return <Page 
        pageName={'Product Types'}
        Component={ProductTypesModule}
        componentProps={{filters, setFilters}}
        sideBarRoutes={sideBarRoutes}
        addButtonObj='Product Type'
        prevPage={prevPage}
        filterFields={filterFields}
    />
}

export function Products(){
    const [filters, setFilters] = useState({commodity: null, productTypeId: null, searchText: null})

    
    const handleFilterChange = ({ commodity, productTypeId, searchText }) => {
        setFilters({
            commodity: commodity === 'All' ? null : parseInt(commodity), 
            productTypeId: productTypeId === 'All' ? null : productTypeId, 
            searchText
        })
    }

    const productTypes = useSelector(state => state.commodityProducts.productTypes)

    const filterFields = [
        {
            type: 'select',
            label: 'Commodity',
            options: Object.keys(CPCommoditiesEnums).map((comm, c) => ({id: parseInt(comm), name: CPCommoditiesEnums[comm]})),
            onChange: ({target: { value }}) => handleFilterChange({...filters, commodity: value}),
            placeholder: 'All',
            singleSelectOnly: true,
            value: filters.commodity
        },
        {
            type: 'select',
            label: 'Product Type',
            options: [...(productTypes || []).filter(
                pt => filters.commodity ? pt.commodity === filters.commodity : true
            ).map((pt, p) => ({id: pt.id, name: pt.code}))],
            onChange: ({target: { value }}) => handleFilterChange({...filters, productTypeId: value}),
            singleSelectOnly: true,
            placeholder: 'All',
            value: filters.productTypeId
        },
        {
            label: 'Search',
            onChange: ({target: { value }}) => handleFilterChange({...filters, searchText: value}),
            value: filters.searchText
        }
    ]

    return <Page 
        pageName={'Products'}
        Component={ProductsModule}
        componentProps={{filters, setFilters}}
        sideBarRoutes={sideBarRoutes}
        addButtonObj='Product'
        prevPage={prevPage}
        filterFields={filterFields}
    />
}

export function DataSources(){
    return <Page 
        pageName={'Data Sources'}
        Component={DataSourceModule}
        sideBarRoutes={sideBarRoutes}
        addButtonObj='Data Source'
        prevPage={prevPage}
    />
}
