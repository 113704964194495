import { useState } from "react";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { SettingsPrivileges } from '../../lib/constants'
import { useNavigate } from "react-router-dom";
import "./header.scss";
import AEConfig from '../../lib/ae-config.json'
import { MarketDataPrivileges } from "../../lib/constants";


const headers = (userData, logout, className='headerMenu') => [
	{
		tabName: "Home",
		path: "/",
		disabled: className === 'headerMenu'
	},
	{
		tabName: "Sales",
		path: "/",
		disabled: true,
	},
	{
		tabName: "Market",
		path: "/market",
		disabled: userData.claims.filter(c => 
			Object.getOwnPropertyNames(MarketDataPrivileges).map(p => MarketDataPrivileges[p]).includes(c.name)
		).length === 0		
	},
	{
		tabName: "Accounts",
		path: "/accounts",
		disabled: true,
	},
	{
		tabName: "Reports",
		path: "/",
		disabled: true,
	},
	{
		tabName: "Settings",
		path: "/settings",
		disabled: userData.claims.filter(c => 
			Object.getOwnPropertyNames(SettingsPrivileges).map(p => SettingsPrivileges[p]).includes(c.name)
		).length === 0
	},
	{
		tabName: "Log Out",
		path: null,
		disabled: false,
		onClick: () => logout({ returnTo: AEConfig.AUTH0_RETURN_TO }),
	},
];

export default function Header() {
	const { logout } = useAuth0();

	const [show, setShow] = useState(false)

	const navigate = useNavigate()

	const userData = useSelector((state) => state.app.me);

	const navClick = (e, header) => {
		setShow(false);
		if (header.path) navigate(header.path)
		else if (!!header.onClick) header.onClick(e) 
	}

	const getHeaders = (className) => <div className={className}>
		{headers(userData, logout, className).filter((header) => !header.disabled).map((header, i) => (
			<nav key={i} className={'navItem'} onClick={e => navClick(e, header)}>
				{header.tabName}
			</nav>
		))}
	</div>	

	return <>
		<div className={'headerContainer'}>
			<div className={'logoContainer flex alignCenter'} onClick={() => navigate('/')}>
				<img src='/images/logo.png' width="100px" alt="ae-logo" />
			</div>
			{getHeaders('headerMenu')}
			{show && getHeaders('headerDrawer')}
		</div>
		<div className="headerDrawerMenu flex alignCenter justifyCenter" onClick={(e) => setShow(!show)}>
			<div className="icon">
				<span className="drawerMenuStroke"/>
				<span className="drawerMenuStroke"/>
				<span className="drawerMenuStroke"/>
			</div>
		</div>
	</>;
}
