import { put, takeEvery, select, call } from "redux-saga/effects";
import { WeatherCalls } from "../../../lib/api";
import { AppActions, WeatherActions } from '../../actions'

export function* executeGetWeatherStations(){
    yield put(AppActions.loading(true))

    const res = yield call(WeatherCalls.getWeatherStations)

    if (res.hasErrors) throw new Error(res.data[0])

    yield put(WeatherActions.Stations.saveWeatherStations(res.data))

    yield put(AppActions.loading(false))
}

export function* executeCreateWeatherStations(action){
    yield put(AppActions.loading(true))

    const res = yield call(WeatherCalls.createWeatherStation, action.payload)

    if (res.hasErrors) throw new Error(res.data[0])

    const stations = yield select(state => state.weather.stations) || []

    yield put(WeatherActions.Stations.saveWeatherStations([res.data, ...stations]))

    yield put(AppActions.loading(false))
}

export function* executeUpdateWeatherStations(action){
    yield put(AppActions.loading(true))

    const res = yield call(
        WeatherCalls.updateWeatherStation, 
        action.payload.id, 
        {
            sourceId: action.payload.sourceId,
            code: action.payload.code,
            name: action.payload.name,
            description: action.payload.description
        }
        
    )

    if (res.hasErrors) throw new Error(res.data[0])

    const stations = yield select(state => state.weather.stations) || []

    yield put(WeatherActions.Stations.saveWeatherStations(stations.map(station => {
        if (station.id === action.payload.id){
            station.name = res.data.name
            station.description = res.data.description
        }

        return station
    })))

    yield put(AppActions.loading(false))
}

export function* executeDeleteWeatherStation(action){
    yield put (AppActions.loading(true))

    const res = yield call(WeatherCalls.deleteWeatherStation, action.payload)

    if (res.hasErrors) throw new Error(res.data[0])

    const stations = yield select(state => state.weather.stations) || []

    yield put(WeatherActions.Stations.saveWeatherStations(stations.filter(station => station.id !== action.payload)))

    yield put (AppActions.loading(false))
}

export default function* StationsWatcher() {
	yield takeEvery(WeatherActions.Stations.types.GET_WEATHER_STATIONS, executeGetWeatherStations);
    yield takeEvery(WeatherActions.Stations.types.CREATE_WEATHER_STATIONS, executeCreateWeatherStations);
    yield takeEvery(WeatherActions.Stations.types.UPDATE_WEATHER_STATION, executeUpdateWeatherStations);
    yield takeEvery(WeatherActions.Stations.types.DELETE_WEATHER_STATION, executeDeleteWeatherStation);
}