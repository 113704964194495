import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { AdminActions, CommodityProductActions } from "../../../../redux/actions"
import {
    CPCommoditiesEnums, CPCommodities, 
    MarketDataPrivileges, Icons 
} from "../../../../lib/constants"
import { Form, Modal, DeleteModal, DataCard, Tabs } from '../../../../components'
import DataSourceCodes from "./dataSourceCodes"
import UpdateRules from "./updateRules"
import Curves from "./curves"

const tierLabels = {
    [CPCommodities.NaturalGas]: {
        tier1: 'Pipeline',
        tier2: 'Point'
    },
    [CPCommodities.Power]: {
        tier1: 'ISO',
        tier2: 'Zone'
    },
    [CPCommodities.Capacity]: {
        tier1: 'ISO',
        tier2: 'LDA'
    },
    [CPCommodities.Environment]: {
        tier1: 'State',
        tier2: 'REC Type'
    }
}

const ProductForm = ({ product={}, canEdit, handleSubmit, cancelEdit, productTypes=[]}) => {
    const [values, setValues] = useState(product)
    const [errors, setErrors] = useState({})

    const productType = values.productType

    const pttPlaceHolder = 'Select product type'

    const fields = [
        {
            fieldName: 'productType',
            value: productType ? productType.id : null,
            label: 'Product Type',
            type: 'select',
            readOnly: !!values.id,
            onChange: ({target: {value}}) => {
                if (value === pttPlaceHolder) return
                setValues({...values, productType: productTypes.filter(pt => pt.id === value)[0]})
            },
            options: productTypes.map(pt => ({ id: pt.id, name: CPCommoditiesEnums[pt.commodity] + ': ' + pt.code})),
            placeholder: pttPlaceHolder,
            singleSelectOnly: true
        },
        {
            fieldName: 'tier1',
            value: values.tier1,
            label: productType && productType.commodity && tierLabels[productType.commodity].tier1,
            readOnly: !canEdit,
            hidden: !productType || !tierLabels[productType.commodity].tier1,
            onChange: ({target: {value}}) => setValues({...values, tier1: value})
        },
        {
            fieldName: 'tier2',
            value: values.tier2,
            label: productType && productType.commodity && tierLabels[productType.commodity].tier2,
            readOnly: !canEdit,
            hidden: !productType || !tierLabels[productType.commodity].tier2,
            onChange: ({target: {value}}) => setValues({...values, tier2: value})
        },
        {
            fieldName: 'tier3',
            value: values.tier3,
            label: 'Tier 3',
            readOnly: !canEdit,
            hidden: !productType || !tierLabels[productType.commodity]['tier3'],
            onChange: ({target: {value}}) => setValues({...values, tier3: value})
        },
        {
            fieldName: 'tier4',
            value: values.tier4,
            label: 'Tier 4',
            readOnly: !canEdit,
            hidden: !productType || !tierLabels[productType.commodity]['tier4'],
            onChange: ({target: {value}}) => setValues({...values, tier4: value})
        },
        {
            fieldName: 'addToMarkingSheet',
            type: 'checkbox',
            readOnly: !canEdit,
            onClick: () => setValues({...values, addToMarkingSheet: !values.addToMarkingSheet}),
            options: [{
                id: 'addToMarkingSheet',
                name: 'Add to Marking Sheet',
                checked: values.addToMarkingSheet
            }]
        }
    ]

    const onSubmit = e => {
        e.preventDefault();
        let errObj = {}

        if (!values.productType) errObj = {...errObj, productType: 'Select a product type'}

        if (Object.keys(errObj).length > 0) {
            setErrors(errObj)
            return
        }

        handleSubmit({...values, productTypeId: values.productType.id})
        
        cancelEdit(e)
    }

    const formActions = [
        { buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit},
        { text: values.id ? 'Update': 'Create', type: 'submit'}
    ]

    return <Form fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />
}

const ProductCard = ({ product, canEdit, dispatch, productTypes, products, dataSources=[], id}) => {
    const [deleteMode, setDeleteMode] = useState(false)

    const handleSubmit = p => dispatch(CommodityProductActions.Products.updateProduct(p))

    let productTitle = product['tier1'] + ' ' + product['tier2']

    let codes = product['codes']

    if (codes.length > 0){
        codes = codes.map(code => {
            let source = dataSources.filter(ds => ds.id === code.sourceId)[0]
            if (!source) return code
            code.source = source.code
            return code
        })

        let displayCode = codes.filter(c => c.source === 'ICE')[0]

        if (!displayCode) displayCode = codes.filter(c => c.source === 'Nex')[0]

        if (!displayCode) displayCode = codes.filter(c => c.source === 'Platts')[0]

        if (!!displayCode){
            displayCode = "[" + displayCode.source + ":" + displayCode.symbol + ']'
            productTitle = [displayCode, productTitle].join(' ')
        }
    }

    const cardTabs = [
        {
            name: 'overview',
            title: 'Overview',
            element: <ProductForm product={product} canEdit={canEdit} handleSubmit={handleSubmit} productTypes={productTypes}/>
        },
        {
            name: 'codes',
            title: 'Codes',
            element: <DataSourceCodes product={product} canEdit={canEdit} dispatch={dispatch} dataSources={dataSources}/>
        },
        {
            name: 'updateRules',
            title: 'Update Rules',
            element: <UpdateRules 
                product={product} 
                canEdit={canEdit} 
                dispatch={dispatch} 
                sources={dataSources} 
                products={products.filter(prod => prod.commodity === product.commodity)} 
                />
        },
        {
            name: 'curves',
            title: 'Curves',
            element: <Curves product={product} dispatch={dispatch} canEdit={canEdit} />
        }
    ]

    return <><DataCard 
        id={id}
        canEdit={canEdit} 
        title={productTitle} 
        subtitle={product.productType['code']}
        actions={[{icon: Icons.DELETE, handler: () => setDeleteMode(true), canEdit: !canEdit}]}
    >
        <Tabs options={cardTabs} />
    </DataCard>
    <DeleteModal 
        deleteMode={deleteMode}
        setDeleteMode={setDeleteMode}
        onDelete={() => {
            dispatch(CommodityProductActions.Products.deleteProduct(product.id))
            setDeleteMode(false)
        }}
        warningMessage={`Are you sure you want to delete data source ${productTitle}?`}
    />
    </>
}

export default function Module ({ canEdit, setCanEdit, showModal, cancelModal, filters, setFilters}){
    const me = useSelector(state => state.app.me)
    const users = useSelector(state => state.admin.users)
    const products = useSelector(state => state.commodityProducts.products)
    const productTypes = useSelector(state => state.commodityProducts.productTypes)
    const dataSources = useSelector(state => state.commodityProducts.dataSources)
    
    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(!!me){
            let authorized = me.claims.filter(c => c.name === MarketDataPrivileges.VIEW_COMMODITY_PRODUCTS).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(me.claims.filter(c => c.name === MarketDataPrivileges.CREATE_EDIT_COMMODITY_PRODUCTS).length > 0)

                if (!productTypes) dispatch(CommodityProductActions.ProductTypes.getProductTypes())
    
                else if (!products) dispatch(CommodityProductActions.Products.getProducts())
    
                else if (!dataSources) dispatch(CommodityProductActions.DataSources.getDataSources())
    
                else if (!users) dispatch(AdminActions.Users.getUsers())
            }            
        }
    })

    return <>
        <div className="moduleContainer">
            {(products || []).map(product => {
                product.productType = productTypes.filter(pt => pt.id === product.productType.id)[0]
                return product
            }).filter(
                product => filters.commodity ? product.commodity === filters.commodity : true
            ).filter(
                product => filters.productTypeId ? product.productType.id === filters.productTypeId : true
            ).filter(
                product => filters.searchText ? filters.searchText.split(' ').filter(st => 
                    (
                        (product.tier1 || '').toLowerCase().includes(st.toLowerCase()) || 
                        (product.tier2 || '').toLowerCase().includes(st.toLowerCase()) || 
                        (product.tier3 || '').toLowerCase().includes(st.toLowerCase()) || 
                        (product.tier4 || '').toLowerCase().includes(st.toLowerCase()) ||
                        product.codes.filter(code => (code.symbol || '').toLowerCase().includes(st.toLowerCase())).length > 0
                    )
                ).length > 0 : true
            ).map((product, p) => <ProductCard 
                key={product.id} 
                id={'productCard' + p}
                product={product} 
                canEdit={canEdit} 
                dispatch={dispatch} 
                productTypes={productTypes}
                dataSources={dataSources || []}
                products={products}
            />
            )}
        </div>
        <Modal 
            title='Add Commodity Product'
            show={showModal}
            cancel={cancelModal}
        >
            <ProductForm 
                canEdit={canEdit} 
                cancelEdit={cancelModal} 
                productTypes={productTypes}
                handleSubmit={product => dispatch(CommodityProductActions.Products.createProduct(product))} 
            />
        </Modal>
    </>
}