import { put, call, takeEvery, select } from "redux-saga/effects";
import { AdminCalls } from "../../../lib/api";
import { AppActions, AdminActions } from '../../actions'


function* executeGetRoles() {
	yield put(AppActions.loading(true))

	const res = yield call(AdminCalls.getRoles)

	if (res.hasErrors){
		throw new Error(res.data[0])
	}

	yield put(AdminActions.Roles.saveRoles(res.data))

	yield put(AppActions.loading(false))
}

function* executeCreateRole(action) {
	yield put(AppActions.loading(true))

	const res = yield call(
		AdminCalls.createRole,
		{
			name: action.payload.name,
			description: action.payload.description,
			claims: action.payload.claims
		}
	)

	if (res.hasErrors) throw new Error(res.data[0])

	let stateRoles = yield select(state => state.admin.roles);

	yield put(AdminActions.Roles.saveRoles([...stateRoles, res.data]))

	stateRoles = yield select(state => state.admin.roles);
	
	yield put(AppActions.loading(false))
}

function* executeUpdateRole(action) {
	yield put(AppActions.loading(true))

	const res = yield call(
		AdminCalls.updateRole,
		action.payload.id,
		{
			description: action.payload.body.description,
			claims: action.payload.body.claims.map(c => c.id)
		}
	)

	if (res.hasErrors) throw new Error(res.data[0])

	let stateRoles = yield select(state => state.admin.roles);

	for (let role of stateRoles){
		if (role.id === action.payload.id){
			role.claims = res.data.claims;
			role.description = res.data.description
		}
	};

	yield put(AdminActions.Roles.saveRoles(stateRoles))

	stateRoles = yield select(state => state.admin.roles);
	
	yield put(AppActions.loading(false))

}

function* executeDeleteRole(action) {
	yield put(AppActions.loading(true));

	const res = yield call(AdminCalls.deleteRole, action.payload);

	if (res.hasErrors) throw new Error(res.data[0])

	// adjust state roles
	let stateRoles = yield select(state => state.admin.roles);

	yield put(AdminActions.Roles.saveRoles(stateRoles.filter(s => s.id !== action.payload)))

	stateRoles = yield select(state => state.admin.roles);

	// Adjust state users
	let stateUsers = yield select(state => state.admin.users);

	if (!!stateUsers){
		for (let user of stateUsers){
			user.roles = user.roles.filter(r => r.id !== action.payload)
		}
	
		yield put(AdminActions.Users.saveUsers(stateUsers))
	}

	// adjust me obj in state
	let me = yield select(state => state.app.me);

	me.roles = me.roles.filter(r => r.id !== action.payload)

	yield put(AppActions.saveMe(me))
	
	yield put(AppActions.loading(false));
}

export default function* RolesWatcher() {
	// Roles
	yield takeEvery(AdminActions.Roles.types.GET_ROLES, executeGetRoles);
	yield takeEvery(AdminActions.Roles.types.UPDATE_ROLE, executeUpdateRole);
	yield takeEvery(AdminActions.Roles.types.CREATE_ROLE, executeCreateRole);
	yield takeEvery(AdminActions.Roles.types.DELETE_ROLE, executeDeleteRole);
}
