import { put, takeEvery, select, call } from "redux-saga/effects";
import { CommodityProductCalls } from "../../../lib/api";
import { AppActions, CommodityProductActions } from '../../actions'

export function* executeGetProductTypes(){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.getProductTypes)

    if (res.hasErrors) throw new Error(res.data[0])

    yield put(CommodityProductActions.ProductTypes.saveProductTypes(res.data))

    yield put(AppActions.loading(false))
}

export function* executeCreateProductType({ payload: {code, commodity, settlement, instrument, index, timeOfUse, description}}){
    yield put(AppActions.loading(true))

    const res = yield call(
        CommodityProductCalls.createProductType, 
        {
            code, commodity, settlement, instrument, index, timeOfUse, description
        }
    )

    if (res.hasErrors) {
        throw new Error(res.data[0])
    }

    const productTypes = yield select(state => state.commodityProducts.productTypes) || []

    yield put(CommodityProductActions.ProductTypes.saveProductTypes([...productTypes, res.data]))

    yield put(AppActions.loading(false))
}

export function* executeUpdateProductType({ payload: {id, settlement, instrument, index, timeOfUse, description}}){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.updateProductType, id, {settlement, instrument, index, timeOfUse, description})

    if (res.hasErrors) {
        
        throw new Error(res.data[0])
    }

    const productTypes = yield select(state => state.commodityProducts.productTypes) || []

    for (let ptx in productTypes){
        if (productTypes[ptx].id === id){
            productTypes[ptx].settlement = res.data.settlement
            productTypes[ptx].instrument = res.data.instrument
            productTypes[ptx].index = res.data.index
            productTypes[ptx].timeOfUse = res.data.timeOfUse
            productTypes[ptx].description = res.data.description
            productTypes[ptx].modifiedAt = res.data.modifiedAt
            productTypes[ptx].modifiedBy = res.data.modifiedBy
        }
    }

    yield put(CommodityProductActions.ProductTypes.saveProductTypes(productTypes))

    yield put(AppActions.loading(false))
}

export function* executeDeleteProductType(action){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.deleteProductType, action.payload)

    if (res.hasErrors) {
        throw new Error(res.data[0])
    }

    const productTypes = yield select(state => state.commodityProducts.productTypes) || []

    yield put(CommodityProductActions.ProductTypes.saveProductTypes(productTypes.filter(pt => pt.id !== action.payload)))

    yield put(AppActions.loading(false))
}

export default function* ProductTypesWatcher() {
	yield takeEvery(CommodityProductActions.ProductTypes.types.GET_PRODUCT_TYPES, executeGetProductTypes);
    yield takeEvery(CommodityProductActions.ProductTypes.types.CREATE_PRODUCT_TYPE, executeCreateProductType);
    yield takeEvery(CommodityProductActions.ProductTypes.types.UPDATE_PRODUCT_TYPE, executeUpdateProductType);
    yield takeEvery(CommodityProductActions.ProductTypes.types.DELETE_PRODUCT_TYPE, executeDeleteProductType);
}