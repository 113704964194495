import Call from "./fetch";

// Sources
export const getWeatherSources = async() => await Call("/weather/sources", "GET")

export const createWeatherSource = async body =>  await Call("/weather/sources", 'POST', body)

export const updateWeatherSource = async (id, body) => await Call("/weather/sources/" + id, 'PUT', body)

export const deleteWeatherSource = async id => await Call("/weather/sources/" + id, 'DELETE')

// Stations
export const getWeatherStations = async()  => await Call("/weather/stations", "GET")

export const createWeatherStation = async body => await Call("/weather/stations", 'POST', body)

export const updateWeatherStation = async (id, body) => await Call("/weather/stations/" + id, "PUT", body)

export const deleteWeatherStation = async id => await Call("/weather/stations/" + id, 'DELETE')
