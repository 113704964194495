export const types = {
    GET_DATA_SOURCES: "GET_DATA_SOURCES",
    SAVE_DATA_SOURCES: "SAVE_DATA_SOURCES",
    CREATE_DATA_SOURCES: "CREATE_DATA_SOURCES",
    UPDATE_DATA_SOURCES: "UPDATE_DATA_SOURCES",
    DELETE_DATA_SOURCES: "DELETE_DATA_SOURCES"
}

export const getDataSources = () => ({type: types.GET_DATA_SOURCES})

export const saveDataSources = payload => ({type: types.SAVE_DATA_SOURCES, payload})

export const createDataSource = payload => ({type: types.CREATE_DATA_SOURCES, payload})

export const updateDataSource = payload => ({type: types.UPDATE_DATA_SOURCES, payload})

export const deleteDataSource = payload => ({type: types.DELETE_DATA_SOURCES, payload})
