import { ProductTypes, Products, DataSources } from '../../modules/market/commodityProducts'

const CommodityProductRoutes = [
    {
        path: 'commodityProducts',
        component: Products,
        subRoutes: [
            {
                path: 'dataSources',
                component: DataSources
            }
        ]
    },
    {
        path: 'commodityProductTypes',
        component: ProductTypes
    },
]

export default CommodityProductRoutes