import Cookies from "universal-cookie";

const cookie = new Cookies();

class cookieService {
  get(key) {
    return cookie.get(key);
  }

  set(key, value, options = null) {
    return cookie.set(key, value, options);
  }

  remove(key) {
    return cookie.remove(key);
  }
}

const service = new cookieService();

export default service
