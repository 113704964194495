import React from 'react';
import { createRoot } from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { Provider } from "react-redux";
import Routes from  './routes'
import store from './redux/store'
import './styles/global.scss'
import AEConfig from './lib/ae-config.json'

const root = createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <Auth0Provider
            domain={AEConfig.AUTH0_DOMAIN}
            clientId={AEConfig.AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: AEConfig.AUTH0_REDIRECT_URI,
                audience: AEConfig.AUTH0_AUDIENCE,
                scope: AEConfig.AUTH0_SCOPES
            }}
        >
            <Routes />
        </Auth0Provider>
    </Provider>
);
