import Button from '../button'
import './modal.scss'


export default function Modal({ title, show, cancel, children, actions }) {
	return show && <div className={"modalContainer flex justifyCenter alignCenter"} onClick={cancel}>
		<div className={'modalContent flex-column justifyCenter'} onClick={evt => evt.stopPropagation()}>
			{title && <div className={'modalContentHeader'}><h2>{title}</h2></div>}
			<div className={'modalContentBody'}>
				<div className={'mcbContainer'}>
					{children}
				</div>
			</div>
			<div className={'modalContentFooter'}>
				{actions && <div className='modalActions flex'> {actions.map((action, a) => (
					<div key={a} className='modalActionButton'>
						<Button {...action} />
					</div>))}
				</div>}
			</div>
		</div>
	</div>
}
