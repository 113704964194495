import Call from "./fetch";

// Data Sources
export const getDataSources = async () => await Call("/commodityProducts/dataSources", 'GET')

export const createDataSource = async body => await Call("/commodityProducts/dataSources", 'POST', body)

export const updateDataSource = async (id, body) => await Call("/commodityProducts/dataSources/" + id, 'PUT', body)

export const deleteDataSource = async id => await Call("/commodityProducts/dataSources/" + id, 'DELETE')

// Product types
export const getProductTypes = async () => await Call("/commodityProductTypes", "GET");

export const createProductType = async (body) => await Call("/commodityProductTypes", "POST", body);

export const updateProductType = async (productTypeId, body) => await Call("/commodityProductTypes/" + productTypeId, "PUT", body)

export const deleteProductType = async (productTypeId) => await Call("/commodityProductTypes/" + productTypeId, "DELETE")

// Products
export const getProducts = async () => await Call("/commodityProducts", "GET");

export const createProduct = async body => await Call("/commodityProducts", "POST", body);

export const updateProduct = async (id, body) => await Call("/commodityProducts/" + id, "PUT", body);

export const deleteProduct = async id => await Call("/commodityProducts/" + id, "DELETE");
