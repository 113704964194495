import './button.scss';


export default function Button({
    buttonType='primaryButton',
    className,
    onClick,
    type='button',
    children
}){
    return <button onClick={onClick} type={type} className={['actionButton', className, buttonType].join(' ')}>
        {children}
    </button>
}