import { AdminActions } from '../actions'

const initialState = {
	users: null,
	roles: null,
	claims: null
}

function reducer(state = initialState, action) {
	switch (action.type) {
		case AdminActions.Users.types.SAVE_USERS:
			return {
				...state,
				users: action.payload
			}

		case AdminActions.Roles.types.SAVE_ROLES:
			return {
				...state,
				roles: action.payload
			}

		case AdminActions.Roles.types.SAVE_CLAIMS:
			return {
				...state,
				claims: action.payload
			}

		default:
			return state
	}
}

export default reducer
