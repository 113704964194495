import { MarketMenu } from '../../modules/market'
import CommodityProductRoutes from './commodityProducts'
import WeatherRoutes from './weather'
import UtilityRoutes from './utilities'

const SettingRoutes = {
    path: 'market',
    component: MarketMenu,
    subRoutes: [
        ...CommodityProductRoutes,
        WeatherRoutes,
        UtilityRoutes
    ]
}

export default SettingRoutes