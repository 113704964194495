import { all } from 'redux-saga/effects'
import { combineWatchers } from 'redux-saga-combine-watchers';
import SourcesWatcher from './sources'
import StationsWatcher from './stations';

export default function* WeatherWatcher() {
    yield all(
        combineWatchers(
            SourcesWatcher,
            StationsWatcher
        )
    )
}