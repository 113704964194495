import './curveCard.scss'


export function IntervalCurveCard({ curve={}, fields=[], actions=[]}){
    if (!curve.id) return <></>

    return <div className="intervalCurveCardContainer fullWidth">
        <div className='icccCurveProperties'>
            {fields.map((field, fdx) => <div className="curveProperty" key={fdx}>
                <div className="curvePropertyKey">{field.title}</div>
                <div className="curvePropertyValue">{curve[field.name]}</div>
            </div>)}
        </div>
        <div className='icccActions flex justifyCenter alignCenter'>
            {actions.map((act, a) => <div key={a} className='icccaAction' onClick={act.onClick}>
                {act.children}
            </div>)}
        </div>
    </div>
}