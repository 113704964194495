import { Page } from "../../components";
import UsersModule from "./users";
import RolesModule from "./roles";


const sideBarRoutes = [
	{title: 'Roles', route: '/settings/roles'},
	{title: 'Users', route: '/settings/users'}
]

export function Roles() {
	return <Page 
		pageName={'Roles'}
		Component={RolesModule}
		sideBarRoutes={sideBarRoutes}
		addButtonObj='Role'
	/>
}

export const Users = () => <Page 
	pageName={'Users'}
	Component={UsersModule}
	sideBarRoutes={sideBarRoutes}
	addButtonObj='User'
/>

