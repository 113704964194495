import { Layout } from "../components"

export default function Home() {
    return <Layout ignoreSidebar={true} >
        <div style={
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 'calc(100vh - var(--header-height) * 4)'
            }
        }>
            <h1 style={{ textAlign: 'center' }}>
                Welcome! Nothing to see here!
            </h1>
        </div>
    </Layout>
}