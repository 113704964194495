import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UtilitiesActions } from '../../../../redux/actions';
import { DataCard, DeleteModal, Form, formField, InputBox, Modal, SelectBox } from '../../../../components';
import { Commodities, CommodityEnums, Icons, RateClassTypeEnums } from '../../../../lib/constants';
import './utility.scss';

const rateClassProperties = [
	{
		property: 'intervalUsagePresent',
		title: 'Interval Usage Present',
		type: 'bool',
	},
	{
		property: 'isInterruptible',
		title: 'Is Interruptible',
		type: 'bool',
	},
	{
		property: 'isMassMarket',
		title: 'Is Mass Market',
		type: 'bool',
	},
];

const Controller = ({ property, type = 'number', value, canEdit, onChange }) => {
	switch (type) {
		case 'bool':
			const options = [
				{ id: '1', name: 'True' },
				{ id: '0', name: 'False' },
			];

			const selected = options.filter(x => x.id === (value === 1 ? '1' : value === 0 ? '0' : null));

			return (
				<div className="propertyController">
					<SelectBox
						singleSelectOnly={true}
						options={options}
						placeholder={'None'}
						selected={selected}
						onChange={({ target: { value } }) => {
							value = value === 'None' ? null : parseInt(value);
							onChange({ property, value });
						}}
					/>
				</div>
			);

		default:
			return (
				<div className="propertyController">
					<InputBox value={value} readOnly={!canEdit} onChange={onChange} textAlign="center" />
				</div>
			);
	}
};

const RateClassProperties = ({ properties = {}, canEdit = true, onChange }) => {
	return (
		<div className="rateClassProperties fullWidth">
			<div className="label">Properties</div>
			<div className="propertiesList">
				{rateClassProperties.map(rcp => (
					<div className="rateClassProp flex fullWidth" key={rcp.property}>
						<div className="propertyHeader">{rcp.title}</div>
						<Controller {...rcp} value={properties[rcp.property]} onChange={onChange} canEdit={canEdit} />
					</div>
				))}
			</div>
		</div>
	);
};

const RateClassForm = ({ rateClass = {}, canEdit = true, onSubmit, cancelEdit, utility }) => {
	const [values, setValues] = useState(rateClass);
	const [errors, setErrors] = useState();

	const handleEDICodeChange = ({ value, index }) => {
		if (index === -1) {
			setValues({ ...values, ediCodes: [...(values.ediCodes || []), value] });
		} else {
			if (!value || value.trim() === '') {
				setValues({ ...values, ediCodes: values.ediCodes.filter((e, d) => d !== index) });
			} else
				setValues({
					...values,
					ediCodes: values.ediCodes.map((e, d) => {
						if (index === d) return value;
						return e;
					}),
				});
		}
	};

	const handlePropertyChange = ({ property, value }) => {
		if (value === null) {
			const newValues = {
				...values,
				properties: {
					...values.properties,
				},
			};

			delete newValues.properties[property];

			setValues(newValues);
		} else
			setValues({
				...values,
				properties: {
					...values.properties,
					[property]: value,
				},
			});
	};

	const fields = [
		formField({
			fieldName: 'commodity',
			label: 'Commodity',
			type: 'select',
			singleSelectOnly: true,
			placeholder: 'Select a commodity',
			readOnly: !!values.id,
			options: Object.entries(CommodityEnums).map(comm => ({ id: parseInt(comm[0]), name: comm[1] })),
			onChange: ({ target: { value } }) => {
				if (value === 'Select a commodity') return;
				setValues({ ...values, commodity: parseInt(value) });
			},
		}),
		formField({
			fieldName: 'code',
			label: 'Code',
			readOnly: !!values.id,
			onChange: ({ target: { value } }) => setValues({ ...values, code: value }),
		}),
		formField({
			fieldName: 'name',
			label: 'Name',
			readOnly: !canEdit,
			onChange: ({ target: { value } }) => setValues({ ...values, name: value }),
		}),
		formField({
			fieldName: 'description',
			label: 'Description',
			readOnly: !canEdit,
			hidden: !values.id,
			onChange: ({ target: { value } }) => setValues({ ...values, description: value }),
		}),
		formField({
			fieldName: 'rateClassType',
			label: 'Rate Class Type',
			type: 'select',
			singleSelectOnly: true,
			placeholder: 'Select rate class type',
			options: Object.entries(RateClassTypeEnums).map(([value, name]) => ({ id: parseInt(value), name })),
			onChange: ({ target: { value } }) => {
				if (value === 'Select rate class type') return;
				setValues({ ...values, rateClassType: parseInt(value) });
			},
			readOnly: !canEdit,
		}),
		formField({
			fieldName: 'ediCodes',
			type: 'array',
			label: 'EDI Codes',
			value: values.ediCodes || [],
			hidden: !values.id,
			readOnly: !canEdit,
			onChange: ({ value, index }) => handleEDICodeChange({ value, index }),
			placeholder: 'Enter EDI code',
		}),
		formField({
			fieldName: 'properties',
			hidden: !values.id,
			type: 'custom',
			element: <RateClassProperties properties={values.properties} canEdit={canEdit} onChange={handlePropertyChange} />,
		}),
	];

	const actions = [
		{ text: 'Cancel', onClick: cancelEdit, buttonType: 'secondaryButton' },
		{ text: values.id ? 'Update' : 'Create', type: 'submit' },
	];

	const handleSubmit = e => {
		e.preventDefault();

		let errorObj = {};

		if (!values.commodity || values.commodity === Commodities.Both) errorObj = { commodity: 'Select valid commodity' };

		if (!values.code || values.code.trim() === '') errorObj = { ...errorObj, code: 'Enter valid code' };
		else if (!values.id && (utility.rateClasses || []).filter(x => x.code === values.code).length > 0)
			errorObj = { ...errorObj, code: 'Code already exists' };

		if (!values.name || values.name.trim() === '') errorObj = { ...errorObj, name: 'Enter valid name' };

		if (!values.rateClassType) errorObj = { ...errorObj, rateClassType: 'Select valid commodity' };

		if (Object.keys(errorObj).length > 0) {
			setErrors(errorObj);
			return;
		}

		setErrors({});

		onSubmit({ ...values, utilityId: utility.id });

		cancelEdit(e);
	};

	return <Form fields={fields} errors={errors} onSubmit={handleSubmit} actions={actions} values={values} />;
};

const RateClassCard = ({ rateClass = {}, canEdit = true, utility, dispatch }) => {
	const [deleteMode, setDeleteMode] = useState(false);

	return (
		<>
			<DataCard
				canEdit={canEdit}
				title={rateClass.code}
				subtitle={rateClass.name}
				actions={[{ icon: Icons.DELETE, handler: () => setDeleteMode(true), canEdit }]}
			>
				<RateClassForm
					rateClass={rateClass}
					canEdit={canEdit}
					utility={utility}
					onSubmit={values => dispatch(UtilitiesActions.updateRateClass(values))}
				/>
			</DataCard>
			<DeleteModal
				deleteMode={deleteMode}
				setDeleteMode={setDeleteMode}
				onDelete={() => dispatch(UtilitiesActions.deleteRateClass(rateClass))}
				warningMessage={'Do you want to delete rate class ' + rateClass.code + '?'}
				title="Delete Rate Class"
			/>
		</>
	);
};

const RateClass = ({ utility, canEdit, showModal, cancelModal }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		if (!utility.rateClasses) {
			dispatch(UtilitiesActions.getRateClasses(utility.id));
		}
	}, [utility.rateClasses, utility.id, dispatch]);

	return (
		<>
			{(utility.rateClasses || []).map(rateClass => (
				<RateClassCard
					key={rateClass.id}
					rateClass={rateClass}
					canEdit={canEdit}
					utility={utility}
					dispatch={dispatch}
				/>
			))}
			<Modal title={`Create ${utility.code} Rate Class`} show={showModal} cancel={cancelModal}>
				<RateClassForm
					onSubmit={values => dispatch(UtilitiesActions.createRateClass(values))}
					cancelEdit={cancelModal}
					utility={utility}
				/>
			</Modal>
		</>
	);
};

export default RateClass;
