import { useEffect, useState } from "react"
import { CommodityProductActions } from "../../../../redux/actions"
import { CPPriceTypeEnums, CPPriceTypes, Icons } from "../../../../lib/constants"
import { SelectBox, InputBox, Spinner, Form } from "../../../../components"
import { useSelector } from "react-redux"


const ALLOWED_PRICE_TYPES = [CPPriceTypes.Close, CPPriceTypes.Open]

const processModeOptions = [{id: 1, name: 'Auto'}, {id: 2, name: 'Synthetic'}]

const operatorOptions = [{id: '+', name: '+'}, {id: '-', name: '-'}]


const ExpressionBuilder = ({priceType, values, products, handleChange, canEdit}) => {
    const handleExpressionChange = ({idx, operator, productId=null, constant=null}) => {
        let newExpression = [...(values.expression || [])]
        if (!operatorOptions.map(o => o.id).includes(operator)) return
        if (idx >= newExpression.length) newExpression.push({constant, operator, productId})
        else newExpression[idx] = {constant, operator, productId}

        handleChange({...values, priceType, expression: newExpression})
    }

    const handleExpressionDelete = ({idx}) => {
        handleChange({...values, priceType, expression: values.expression.filter((exp, e) => e !== idx)})
    }

    const productOptions = products.map(prod => ({
        id: prod.id, 
        name: prod.tier1 + ' ' + prod.tier2 + ' (' + prod.productType.code + ')'
    }))


    const ExpressionRow = ({exp, idx}) => {
        return <tr>
            <td className="exprOperator"><SelectBox 
                options={operatorOptions}
                singleSelectOnly={true}
                selected={operatorOptions.filter(o => o.id === exp.operator)}
                onChange={({ target: { value }}) => handleExpressionChange({idx, ...exp, operator: value})}
                placeholder={''}
                disabled={!canEdit}
                style={{maxWidth: '80px'}}
            /></td>
            <td>{exp.operator 
                    ? <SelectBox 
                        options={productOptions}
                        singleSelectOnly={true}
                        selected={productOptions.filter(o => o.id === exp.productId)}
                        disabled={!canEdit}
                        onChange={({ target: { value }}) => handleExpressionChange({idx, ...exp, productId: value})}
                        />
                    : <></>
            }</td>
            <td>{exp.operator 
                ? <InputBox 
                    value={exp.constant || ''} 
                    onChange={({target: { value }}) => handleExpressionChange({idx, ...exp, constant: parseInt(value)})} 
                    textAlign="center"
                    readOnly={!canEdit}
                /> 
                :<></>
            }</td>
            <td style={{minWidth: '20px'}} >
                {exp.operator && canEdit && idx > 0
                    ? <div className="urExpCancel" onClick={() => handleExpressionDelete({idx, ...exp})}>{Icons.CANCEL}</div>
                    : <></>
                }</td>
        </tr>
    }

    return <table className="fullWidth urExpressions">
        <thead>
            <tr>
                <th>Operator</th>
                <th>Product</th>
                <th>Constant</th>
                {canEdit ? <th></th> : <></>}
            </tr>
        </thead>
        <tbody>
            {(values.expression || []).map((exp, e) => <ExpressionRow key={e} exp={exp} idx={e} />)}
            {canEdit && <ExpressionRow exp={{}} idx={(values.expression || []).length}/>}
        </tbody>
    </table>
}


const PriceTypeUpdateRule = ({priceType, values, sources, products, handleChange, canEdit=true}) => {
    return <div className="urPriceTypeUpdateRule">
        <span className="ptcTitle">{CPPriceTypeEnums[priceType]}</span>
        <div className="updateRulesTable">
            <SelectBox 
                options={processModeOptions}
                label='Proces Mode'
                placeholder={'Updates Off'}
                singleSelectOnly={true}
                selected={processModeOptions.filter(o => o.id === values.processMode)}
                disabled={!canEdit}
                onChange={({ target: { value }}) => handleChange({...values, priceType, processMode: value})}
            />
            {
                values.processMode === 1 
                    ?   <SelectBox 
                            label='Source'
                            options={sources.map(src => ({id: src.id, name: src.code}))}
                            placeholder={'None'}
                            singleSelectOnly={true}
                            disabled={!canEdit}
                            selected={sources.filter(src => src.id === values.sourceId).map(src => ({id: src.id, name: src.code}))}
                            onChange={({ target: { value }}) => handleChange({...values, priceType, sourceId: value})}
                        />
                    : values.processMode === 2 
                        ? <ExpressionBuilder 
                            priceType={priceType}
                            values={values}
                            products={products}
                            handleChange={handleChange}
                            canEdit={canEdit}
                        />
                        : <></>

            }
        </div>
    </div>
}


const UpdateRules = ({ product={}, canEdit, cancelEdit, dispatch, sources=[], products=[]}) => {
    const inAppLoading = useSelector(state => state.app.inAppLoading)

    // Using json parse and stringify to make deep copies, it will update props otherwise
    const [values, setValues] = useState(JSON.parse(JSON.stringify(product.updateRules || [])))

    useEffect(() => {
        if (!product.updateRules) dispatch(CommodityProductActions.Products.getProductUpdateRules(product.id))
        // Using json parse and stringify to make deep copies, it will update props otherwise
        else setValues(JSON.parse(JSON.stringify(product.updateRules)))
    }, [product.updateRules, product.id, dispatch])

    const handleChange = ({ priceType, processMode, sourceId=null, expression=null }) => {
        if (![1, '1', 2, '2'].includes(processMode)) {
            setValues(values.filter(val => val.priceType !== priceType)) 
            return
        }

        processMode = parseInt(processMode)

        if (processMode === 2 && expression === null) {
            // default expression value
            expression = [{ operator: '+', constant: null, productId: null }]
        }

        let priceTypeUpdateRule = values.filter(val => val.priceType === priceType)[0]

        if (!priceTypeUpdateRule){
            setValues([
                ...values,
                {
                    priceType, 
                    processMode, 
                    sourceId: sourceId,
                    expression: expression
                }
            ])
            return
        }

        setValues(values.map(val => {
            if (val.priceType === priceType){
                val.processMode = processMode
                val.sourceId = sourceId
                val.expression = expression
            }
            return val
        }))
    }

    const updateUpdateRules = () => {
        // Check output thoroughly
        const updateObj = {
            productId: product.id,
            updateRules: values.map(
                val => ({
                    ...val,
                    sourceId: val.processMode === 1 ? val.sourceId : null,
                    expression: val.processMode === 2 
                        ? (val.expression || []).filter(exp => !!exp.operator && (!!exp.productId || !!exp.constant)) 
                        : null
                })
            )
        }
        dispatch(CommodityProductActions.Products.updateProductUpdateRule(updateObj))
    }

    const formActions = [
        {text: 'Cancel', buttonType: 'secondaryButton', onClick: cancelEdit},
        {text: 'Update', onClick: updateUpdateRules}
    ]

    return <div className="commProdUpdateRules">
        {
            (inAppLoading && !product.updateRules) 
                ? <Spinner size="s" text={'Getting update rules'}/>
                : ALLOWED_PRICE_TYPES.map((pt, p) => {
                    const updateRule = (values || []).filter(ur => ur.priceType === pt)[0] || {}

                    return <PriceTypeUpdateRule 
                        key={`${pt}_${updateRule.processMode}`}
                        priceType={pt}
                        values={{
                            ...updateRule,
                            expression: updateRule.expression ? [...updateRule.expression] : updateRule.expression
                        }}
                        sources={sources}
                        products={products}
                        handleChange={handleChange}
                        canEdit={canEdit}
                    />})
        }
        <Form fields={[]} errors={{}} onSubmit={null} actions={formActions} canEdit={canEdit}/>
    </div>
}

export default UpdateRules
