import { WeatherActions } from '../actions'

const initialState = {
	sources: null,
    stations: null
}

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case WeatherActions.Sources.types.SAVE_WEATHER_SOURCES:
			return {
				...state,
				sources: action.payload
			}

			case WeatherActions.Stations.types.SAVE_WEATHER_STATIONS:
				return {
					...state,
					stations: action.payload
				}

		default:
			return state
	}
}
