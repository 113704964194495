import { put, takeEvery, select, call } from "redux-saga/effects";
import { CommodityProductCalls } from "../../../lib/api";
import Call from "../../../lib/api/fetch";
import { AppActions, CommodityProductActions } from '../../actions'

export function* executeGetProducts(){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.getProducts)

    if (res.hasErrors) throw new Error(res.data[0])

    yield put(CommodityProductActions.Products.saveProducts(res.data))

    yield put(AppActions.loading(false))
}

export function* executeCreateProduct({payload}){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.createProduct, payload)

    if (res.hasErrors) throw new Error(res.data[0])

    const products = yield select(state => state.commodityProducts.products) 

    yield put(CommodityProductActions.Products.saveProducts([...(products || []), res.data]))

    yield put(AppActions.loading(false))
}

export function* executeUpdateProduct({payload}){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.updateProduct, payload.id, payload)

    if (res.hasErrors) throw new Error(res.data[0])

    const products = yield select(state => state.commodityProducts.products)

    for (let dx in products){
        if (products[dx].id === payload.id) products[dx] = res.data
    }

    yield put(CommodityProductActions.Products.saveProducts(products))

    yield put(AppActions.loading(false))
}

export function* executeDeleteProduct({payload}){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.deleteProduct, payload)

    if (res.hasErrors) throw new Error(res.data[0])

    const products = yield select(state => state.commodityProducts.products)

    yield put(CommodityProductActions.Products.saveProducts(products.filter(p => p.id !== payload)))

    yield put(AppActions.loading(false))
}

export function* executeGetProductUpdateRules({payload}){
    yield put (AppActions.inAppLoading(true))

    const res = yield call(Call, "/commodityProducts/" + payload +'/updateRules', 'GET')

    if (res.hasErrors) throw new Error(res.data[0])

    const products = yield select(state => state.commodityProducts.products) 

    yield put(CommodityProductActions.Products.saveProducts(products.map(prod => {
        if (prod.id === payload) prod.updateRules = res.data
        return prod
    })))

    yield put (AppActions.inAppLoading(false))
}

export function* executeUpdateProductUpdateRules({ payload }){
    const {updateRules, productId} = payload

    yield put (AppActions.inAppLoading(true))

    let product = yield select(state => state.commodityProducts.products)
    
    product = product.filter(p => p.id === productId)[0]

    const oldUpdateRules = JSON.parse(JSON.stringify(product.updateRules || []))

    let newUpdateRules = []

    for (let updateRule of updateRules){
        if (!updateRule.id){
            let res = yield call(Call, "/commodityProducts/" + productId +'/updateRules', 'POST', updateRule)
            newUpdateRules.push(res.data)
        }

        else {
            let res = yield call(Call, "/commodityProducts/" + productId + '/updateRules/' + updateRule.id, 'PUT', updateRule)
            newUpdateRules.push(res.data)
        }
    }

    for (let rdx in oldUpdateRules){
        if (!payload.updateRules.map(ur => ur.id).includes(oldUpdateRules[rdx].id)){
            yield call(Call, "/commodityProducts/" + productId + '/updateRules/' + product.updateRules[rdx].id, 'DELETE')
        }
    }

    let products = yield select(state => state.commodityProducts.products)

    for (let pdx in products){
        if (products[pdx].id === product.id) products[pdx].updateRules = newUpdateRules
    }

    yield put(CommodityProductActions.Products.saveProducts(products))

    yield put (AppActions.inAppLoading(false))
}

export function* executeGetProductCurves({ payload }){
    yield put (AppActions.inAppLoading(true))

    let res = yield call(Call, '/commodityProducts/' + payload + '/curves', 'GET')

    const products = yield select(state => state.commodityProducts.products)

    yield put (CommodityProductActions.Products.saveProducts(products.map(product => {
        if (product.id === payload) product.curves = res.data
        return product
    })))

    yield put (AppActions.inAppLoading(false))
}

export default function* ProductsWatcher() {
	yield takeEvery(CommodityProductActions.Products.types.GET_PRODUCTS, executeGetProducts);
    yield takeEvery(CommodityProductActions.Products.types.CREATE_PRODUCT, executeCreateProduct);
    yield takeEvery(CommodityProductActions.Products.types.UPDATE_PRODUCT, executeUpdateProduct);
    yield takeEvery(CommodityProductActions.Products.types.DELETE_PRODUCT, executeDeleteProduct);
    yield takeEvery(CommodityProductActions.Products.types.GET_PRODUCT_UPDATE_RULES, executeGetProductUpdateRules);
    yield takeEvery(CommodityProductActions.Products.types.UPDATE_PRODUCT_UPDATE_RULE, executeUpdateProductUpdateRules);
    yield takeEvery(CommodityProductActions.Products.types.GET_PRODUCT_CURVES, executeGetProductCurves)
}