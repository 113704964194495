import { all } from 'redux-saga/effects'
import { combineWatchers } from 'redux-saga-combine-watchers';
import ClaimsWatcher from './claims'
import RolesWatcher from './roles'
import UsersWatcher from './users'

export default function* AdminWatcher() {
    yield all(
        combineWatchers(
            ClaimsWatcher,
            RolesWatcher,
            UsersWatcher
        )
    )
}