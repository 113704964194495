import { put, takeEvery, select, call } from "redux-saga/effects";
import Call from "../../../lib/api/fetch";
import { AppActions, UtilitiesActions } from '../../actions'

const types = UtilitiesActions.types

export function* executeGetUtilities(){
    yield put(AppActions.loading(true))

    let res
    try{
        res = yield call(() => Call('/utilities'))

    } catch (e){
        res = {
            hasErrors: true,
            data: []
        }
    }

    if (res.hasErrors) {
        yield put(AppActions.loading(false))
        return    
    }

    yield put(UtilitiesActions.saveUtilities(res.data))

    yield put(AppActions.loading(false))
}

export function* executeUpdateUtilities({ payload }){
    yield put(AppActions.loading(true))

    let res

    try{
        res = yield call(() => Call('/utilities/' + payload.id, 'PUT',  payload))
    } catch(e){
        return
    }
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.map(u => {
        if (u.id === payload.id) return res.data
        else return u
    })))

    yield put(AppActions.loading(false))
}

export function* executeDeleteUtility({payload}){
    yield put(AppActions.loading(true))

    let res

    res = yield call(() => Call('/utilities/' + payload, 'DELETE'))
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.filter(x => x.id !== payload)))

    yield put(AppActions.loading(false))
}

// Rate classes
export function* executeGetRateClasses({payload}){
    yield put(AppActions.loading(true))

    let res

    res = yield call(() => Call('/utilities/' + payload + '/rateClasses'))
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.map(u => {
        if (u.id === payload) u.rateClasses = res.data 
        return u
    })))

    yield put(AppActions.loading(false))
}

export function* executeCreateRateClass({ payload }) {
    yield put(AppActions.loading(true))

    let res

    res = yield call(() => Call('/utilities/' + payload.utilityId + '/rateClasses', 'POST', payload))
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.map(u => {
        if (u.id === payload.utilityId) u.rateClasses = [...u.rateClasses, res.data]
        return u
    })))

    yield put(AppActions.loading(false))
}

export function* executeUpdateRateClass({payload}){
    yield put(AppActions.loading(true))

    let res

    res = yield call(() => Call('/utilities/' + payload.utilityId + '/rateClasses/' + payload.id, 'PUT', payload))
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.map(u => {
        if (u.id === payload.utilityId) u.rateClasses = u.rateClasses.map(rc => {
            if (rc.id === payload.id) return res.data
            return rc
        })
        return u
    })))

    yield put(AppActions.loading(false))
}

export function* executeDeleteRateClass({payload}){
    yield put(AppActions.loading(true))

    let res

    res = yield call(() => Call('/utilities/' + payload.utilityId + '/rateClasses/' + payload.id, 'DELETE'))
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.map(u => {
        if (u.id === payload.utilityId) u.rateClasses = u.rateClasses.filter(rc => rc.id !== payload.id)
        return u
    })))

    yield put(AppActions.loading(false))
}

// Load profiles
export function* executeGetLoadProfiles({payload}){
    yield put(AppActions.loading(true))

    let res

    res = yield call(() => Call('/utilities/' + payload + '/loadProfiles'))
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.map(u => {
        if (u.id === payload) u.loadProfiles = res.data 
        return u
    })))

    yield put(AppActions.loading(false))
}

export function* executeCreateLoadProfile({ payload }) {
    yield put(AppActions.loading(true))

    let res

    res = yield call(() => Call('/utilities/' + payload.utilityId + '/loadProfiles', 'POST', payload))
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.map(u => {
        if (u.id === payload.utilityId) u.loadProfiles = [...u.loadProfiles, res.data]
        return u
    })))

    yield put(AppActions.loading(false))
}

export function* executeUpdateLoadProfile({payload}){
    yield put(AppActions.loading(true))

    let res

    res = yield call(() => Call('/utilities/' + payload.utilityId + '/loadProfiles/' + payload.id, 'PUT', payload))
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.map(u => {
        if (u.id === payload.utilityId) u.loadProfiles = u.loadProfiles.map(lp => {
            if (lp.id === payload.id) return res.data
            return lp
        })
        return u
    })))

    yield put(AppActions.loading(false))
}

export function* executeDeleteLoadProfile({payload}){
    yield put(AppActions.loading(true))

    let res

    res = yield call(() => Call('/utilities/' + payload.utilityId + '/loadProfiles/' + payload.id, 'DELETE'))
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.map(u => {
        if (u.id === payload.utilityId) u.loadProfiles = u.loadProfiles.filter(lp => lp.id !== payload.id)
        return u
    })))

    yield put(AppActions.loading(false))
}

// Meter read schedules
export function* executeGetSchedules({ payload }){
    yield put(AppActions.loading(true))

    let res

    res = yield call(() => Call('/utilities/' + payload + '/schedules'))
    
    if (res.hasErrors) throw new Error(res.data[0])

    const utilities = yield select(state => state.utilities)

    yield put(UtilitiesActions.saveUtilities(utilities.map(u => {
        if (u.id === payload) u.schedules = res.data 
        return u
    })))

    yield put(AppActions.loading(false))
}

export default function* UtilitiesWatcher() {
	yield takeEvery(types.GET_UTILITIES, executeGetUtilities);
    yield takeEvery(types.UPDATE_UTILITY, executeUpdateUtilities);
    yield takeEvery(types.DELETE_UTILITY, executeDeleteUtility);

    // Rate classes
    yield takeEvery(types.GET_RATE_CLASSES, executeGetRateClasses);
    yield takeEvery(types.CREATE_RATE_CLASS, executeCreateRateClass);
    yield takeEvery(types.UPDATE_RATE_CLASS, executeUpdateRateClass);
    yield takeEvery(types.DELETE_RATE_CLASS, executeDeleteRateClass);

    // Load profiles
    yield takeEvery(types.GET_LOAD_PROFILES, executeGetLoadProfiles);
    yield takeEvery(types.CREATE_LOAD_PROFILES, executeCreateLoadProfile);
    yield takeEvery(types.UPDATE_LOAD_PROFILES, executeUpdateLoadProfile);
    yield takeEvery(types.DELETE_LOAD_PROFILES, executeDeleteLoadProfile);

    // Meter read schedules
    yield takeEvery(types.GET_METER_READ_SCHEDULES, executeGetSchedules);
};