import { put, takeEvery, select, call } from "redux-saga/effects";
import { WeatherCalls } from "../../../lib/api";
import { AppActions, WeatherActions } from '../../actions'

export function* executeGetWeatherSources(){
    yield put(AppActions.loading(true))

    const res = yield call(WeatherCalls.getWeatherSources)

    if (res.hasErrors) throw new Error(res.data[0])

    yield put(WeatherActions.Sources.saveWeatherSources(res.data))

    yield put(AppActions.loading(false))
}

export function* executeCreateWeatherSource(action){
    yield put (AppActions.loading(true))

    const res = yield call(
        WeatherCalls.createWeatherSource, 
        {code: action.payload.code, name: action.payload.name}
    )
    
    if (res.hasErrors) throw new Error(res.data[0])

    const sources = yield select(state => state.weather.sources) || []

    yield put(WeatherActions.Sources.saveWeatherSources([...sources, res.data]))

    yield put(AppActions.loading(false))
}

export function* executeUpdateWeatherSource(action){
    yield put (AppActions.loading(true))

    const res = yield call(
        WeatherCalls.updateWeatherSource, 
        action.payload.id,
        {code: action.payload.code, name: action.payload.name}
    )
    
    if (res.hasErrors) throw new Error(res.data[0])

    const sources = yield select(state => state.weather.sources) || []

    yield put(WeatherActions.Sources.saveWeatherSources(sources.map(s => {
        if (s.id === action.payload.id){
            s.code = res.data.code
            s.name = res.data.name
        }
        return s
    })))

    yield put(AppActions.loading(false))
}

export function* executeDeleteWeatherSource(action){
    yield put (AppActions.loading(true))

    const res = yield call(WeatherCalls.deleteWeatherSource, action.payload)
    
    if (res.hasErrors) throw new Error(res.data[0])

    const sources = yield select(state => state.weather.sources) || []

    yield put(WeatherActions.Sources.saveWeatherSources(sources.filter(s => s.id !== action.payload)))

    const stations = yield select(state => state.weather.stations) 

    if (!!stations) yield put(WeatherActions.Stations.saveWeatherStations(stations.filter(s => s.sourceId !== action.payload)))

    yield put(AppActions.loading(false))
}

export default function* SourcesWatcher() {
	yield takeEvery(WeatherActions.Sources.types.GET_WEATHER_SOURCES, executeGetWeatherSources);
    yield takeEvery(WeatherActions.Sources.types.CREATE_WEATHER_SOURCE, executeCreateWeatherSource);
    yield takeEvery(WeatherActions.Sources.types.UPDATE_WEATHER_SOURCE, executeUpdateWeatherSource);
    yield takeEvery(WeatherActions.Sources.types.DELETE_WEATHER_SOURCE, executeDeleteWeatherSource);
}