import { put, takeEvery } from "redux-saga/effects";
import { AdminCalls } from "../../../lib/api";
import { AppActions, AdminActions } from '../../actions'


function* executeGetClaims() { 
	yield put(AppActions.loading(true))

	const res = yield AdminCalls.getClaims()

	if (res.hasErrors){
		throw new Error(res.data[0])
	}

	yield put(AdminActions.Roles.saveClaims(res.data))

	yield put(AppActions.loading(false))
}


export default function* ClaimsWatcher() {
	yield takeEvery(AdminActions.Roles.types.GET_CLAIMS, executeGetClaims);
}
