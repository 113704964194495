import { put, takeEvery, select, call } from "redux-saga/effects";
import { CommodityProductCalls } from "../../../lib/api";
import { AppActions, CommodityProductActions } from '../../actions'

export function* executeGetDataSources(){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.getDataSources)

    if (res.hasErrors) throw new Error(res.data[0])

    yield put(CommodityProductActions.DataSources.saveDataSources(res.data))

    yield put(AppActions.loading(false))
}

export function* executeCreateDataSource({ payload: { code, description }}){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.createDataSource, {code, description})

    if (res.hasErrors) throw new Error(res.data[0])

    const dataSources = yield select(state => state.commodityProducts.dataSources)

    yield put(CommodityProductActions.DataSources.saveDataSources([...(dataSources || []), res.data]))

    yield put(AppActions.loading(false))
}

export function* executeUpdateDataSource({ payload: { id, code, description }}){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.updateDataSource, id, {code, description})

    if (res.hasErrors) throw new Error(res.data[0])

    const dataSources = yield select(state => state.commodityProducts.dataSources)

    for (let dx in dataSources){
        if (dataSources[dx].id === id){
            dataSources[dx].code = res.data.code
            dataSources[dx].description = res.data.description
            dataSources[dx].modifiedAt = res.data.modifiedAt
            dataSources[dx].modifiedBy = res.data.modifiedBy
        }

    }
    yield put(CommodityProductActions.DataSources.saveDataSources(dataSources))

    yield put(AppActions.loading(false))
}

export function* executeDeleteDataSource({ payload }){
    yield put(AppActions.loading(true))

    const res = yield call(CommodityProductCalls.deleteDataSource, payload)

    if (res.hasErrors) throw new Error(res.data[0])

    const dataSources = yield select(state => state.commodityProducts.dataSources)

    yield put(CommodityProductActions.DataSources.saveDataSources(dataSources.filter(ds => ds.id !== payload)))

    yield put(AppActions.loading(false))
}

export default function* DataSourcesWatcher() {
	yield takeEvery(CommodityProductActions.DataSources.types.GET_DATA_SOURCES, executeGetDataSources);
    yield takeEvery(CommodityProductActions.DataSources.types.CREATE_DATA_SOURCES, executeCreateDataSource);
    yield takeEvery(CommodityProductActions.DataSources.types.UPDATE_DATA_SOURCES, executeUpdateDataSource);
    yield takeEvery(CommodityProductActions.DataSources.types.DELETE_DATA_SOURCES, executeDeleteDataSource);
}