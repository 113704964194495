import Header from "../header";
import SideBar from "../sidebar";
import Loader from "./loading";
import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector, useDispatch } from "react-redux";
import { AppActions } from "../../redux/actions";
import cookieService from "../../lib/helpers/cookieService";
import { useNavigate } from 'react-router-dom'
import Button from "../button"
import './layout.scss'
import { IoIosArrowDroprightCircle } from "react-icons/io";
import Form from "../form";
import Notification from "../notification";


export default function Layout({
	actions=[],
	children,
	header,
	centerHeader=false,
	sideBarProps={
		navProps: [],
		prevPage: null
	},
	filterFields=[]
}) {
	const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

	const {loading, me} = useSelector(state => state.app);

	const dispatch = useDispatch();

	const navigate = useNavigate()

	const [showSidebar, setShowSidebar] = useState(false)

	const [showFilter, setShowFilter] = useState(false)

	let ignoreSidebar = sideBarProps.navProps.length === 0 && !sideBarProps.prevPage

	useEffect(() => {
		if (!isLoading){
			if (!isAuthenticated) navigate('/login')
			
			getAccessTokenSilently().then(
				res => {
					cookieService.set("ae_app_user_jwt", res, {
						'sameSite': 'none',
						'secure': 'true'
					})
					if (!me & !loading) {
						dispatch(AppActions.getMe())
					}
				}
			).catch(err => {
				if (err.error && err.error === 'login_required'){ }
				else throw err
			})
		}
	}, [isLoading, me, loading, isAuthenticated, dispatch, getAccessTokenSilently, navigate]);

	if (isLoading || !me || loading) return <Loader />

	return <>
		<Header />
		<Notification />
		<div className={'LayoutContainer'}>
			{ignoreSidebar ? <></> : <SideBar {...sideBarProps} showSidebarDrawer={showSidebar} closeSidebar={() => setShowSidebar(false)} />}
			<div className={'layoutWrapper'}>
				<div className={'layoutHeader flex alignCenter fullWidth'}>
					<div className={'layoutTitle flex alignCenter' + (centerHeader ? ' fullWidth' : '')}>
						{!ignoreSidebar && <div className='sidebarDrawerButton' onClick={() => setShowSidebar(true)}>
							<IoIosArrowDroprightCircle />
						</div>}
						<h1>{header}</h1>
					</div>
					{actions && actions.length > 0 &&  <div className={'layoutActionButtons flex'}>
						{actions.map((action, a) => <div key={a} className="layoutActionButton">
							<Button {...action} />
						</div>)}
					</div>}
				</div>
				<Form fields={filterFields} className='layoutFilter'/>
				{filterFields.length > 0 && <Button 
					className={'fullWidth filterButton'} 
					buttonType="secondaryButton" 
					onClick={() => setShowFilter(!showFilter)}
				>
					Filter
				</Button>}
				{showFilter && <Form fields={filterFields} className='layoutFilter layoutFilterDropdown'/>}
				<div className={'content'}>{children}</div>
			</div>
		</div>
	</>;
}
