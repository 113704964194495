import { all } from 'redux-saga/effects'
import { combineWatchers } from 'redux-saga-combine-watchers';
import DataSourcesWatcher from './dataSources'
import ProductTypesWatcher from './productTypes';
import ProductsWatcher from './products';

export default function* WeatherWatcher() {
    yield all(
        combineWatchers(
            DataSourcesWatcher,
            ProductsWatcher,
            ProductTypesWatcher
        )
    )
}