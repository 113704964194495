import { AppActions } from '../actions'

const initialState = {
	loading: false,
	inAppLoading: false,
	me: null,
	notifications: []
}

function reducer(state = initialState, action) {
	switch (action.type) {
		case AppActions.types.LOADING:
			return {
				...state,
				loading: action.status
			}

		case AppActions.types.SAVE_ME:
			return {
				...state,
				me: action.payload
			}

		case AppActions.types.IN_APP_LOADING:
			return {
				...state,
				inAppLoading: action.payload
			}
		
		case AppActions.types.ADD_NOTIFICATION:
			return {
				...state,
				notifications: [
					...state.notifications,
					{
						...action.payload,
						createdAt: new Date()
					}
				]
			}
		
		case AppActions.types.REMOVE_NOTIFICATION:
			return {
				...state,
				notifications: state.notifications.filter((n, idx) => n.createdAt !== action.payload)
			}

		case AppActions.types.SAVE_NOTIFICATIONS:
			return {
				...state,
				notifications: action.payload
			}

		default:
			return state
	}
}

export default reducer
