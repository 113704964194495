import { UtilitiesActions  } from '../actions'

const { SAVE_UTILITIES, SAVE_RATE_CLASSES, SAVE_LOAD_PROFILES, SAVE_METER_READ_SCHEDULES } = UtilitiesActions.types

const initialState = null

export default function reducer(state = initialState, action) {
    const { type, payload } = action;

    const { utilityId } = payload || {};

	switch (type) {
		case SAVE_UTILITIES:
			return payload

        case SAVE_RATE_CLASSES:
            const { rateClasses } = payload;

            return state.map(util => {
                if (util.id === utilityId) util.rateClasses = rateClasses;
                return util
            })

        case SAVE_LOAD_PROFILES:
            const { loadProfiles } = payload;

            return state.map(util => {
                if (util.id === utilityId) util.loadProfiles = loadProfiles;
                return util
            })

        case SAVE_METER_READ_SCHEDULES:
            const { schedules } = payload;

            return state.map(util => {
                if (util.id === utilityId) util.schedules = schedules;
                return util
            })

		default:
			return state
	}
}
