import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UtilitiesActions } from "../../../../redux/actions";
import { Modal, DeleteModal, Form, formField, DataCard } from "../../../../components";
import { Icons } from "../../../../lib/constants";

const LoadProfileForm = ({utility, loadProfile={}, canEdit=true, handleSubmit, cancelEdit}) => {
    const rateClassOptions = utility.rateClasses.map(rc => ({id: rc.id, name: rc.code + ': ' + rc.name}))
    const [values, setValues] = useState(loadProfile)
    const [errors, setErrors]   = useState({})

    const fields = [
        formField({
            fieldName: 'code',
            label: 'Code',
            readOnly: !!values.id,
            onChange: ({target: {value}}) => setValues({...values, code: value})
        }),
        formField({
            fieldName: 'description',
            label: 'Description',
            readOnly: !canEdit,
            onChange: ({target: {value}}) => setValues({...values, description: value})
        }),
        formField({
            fieldName: 'rateClassId',
            type:'select',
            singleSelectOnly: true,
            label: 'Rate Class',
            placeholder: '',
            options: rateClassOptions,
            onChange: ({target: {value}}) => setValues({...values, rateClassId: value === '' ? null: value})
        })
    ]

    const onSubmit = e => {
        e.preventDefault()
        
        handleSubmit({...values, utilityId: utility.id})
        cancelEdit(e)
    }

    const actions = [
        {text: 'Cancel', buttonType: 'secondaryButton', onClick: cancelEdit},
        {text: values.id ? 'Update' : 'Create', type: 'submit'}
    ]

    return <Form fields={fields} values={values} onSubmit={onSubmit} canEdit={canEdit} actions={actions} />
}


const LoadProfileCard = ({loadProfile = {}, utility, canEdit, dispatch }) => {
    const [deleteMode, setDeleteMode] = useState(false)

    const actions = [
        {icon: Icons.DELETE, handler: () => setDeleteMode(true), canEdit: !canEdit}
    ]

    const updateLoadProfile = values => dispatch(UtilitiesActions.updateLoadProfile(values))

    return <>
        <DataCard 
            title={loadProfile.code}
            subtitle={loadProfile.description}
            actions={actions}
        >
            <LoadProfileForm loadProfile={loadProfile} utility={utility} canEdit={canEdit} handleSubmit={updateLoadProfile}/>
        </DataCard>
        <DeleteModal 
            deleteMode={deleteMode}
            setDeleteMode={setDeleteMode}
            title="Delete Load Profile"
            onDelete={() => dispatch(UtilitiesActions.deleteLoadProfile({utilityId: loadProfile.utilityId, id: loadProfile.id }))}
            warningMessage={"Are you sure you want to delete load profile " + loadProfile.code + '?'}
        />
    </>
}


const LoadProfiles = ({utility, canEdit, showModal, cancelModal}) => {
    const dispatch = useDispatch()

    useEffect(() => {
        if (!utility.rateClasses) dispatch(UtilitiesActions.getRateClasses(utility.id));
        else if (!utility.loadProfiles) dispatch(UtilitiesActions.getLoadProfiles(utility.id));
    }, [utility])

    const createLoadProfile = values => dispatch(UtilitiesActions.createLoadProfile(values))

    return <>
        {
            (utility.loadProfiles || []).length === 0 ? <>Load profiles not found</>
            : utility.loadProfiles.map((lp, l) => <LoadProfileCard 
                key={lp.id} 
                loadProfile={lp}
                canEdit={canEdit} 
                dispatch={dispatch}
                utility={utility}
            />)
        }
        <Modal
            show={showModal}
            cancel={cancelModal}
            title={'Add  ' + utility.code + ' Load Profile'}
        >
            <LoadProfileForm utility={utility} handleSubmit={createLoadProfile} canEdit={canEdit} cancelEdit={cancelModal} />
        </Modal>
    </>
}

export default LoadProfiles;
