import { all } from 'redux-saga/effects'
import { combineWatchers } from 'redux-saga-combine-watchers';

import AdminWatcher from './admin'
import AppWatcher from './app'
import CommodityProductsWatcher from './commodityProducts'
import UtilitiesWatcher from './utilities'
import WeatherWatcher from './weather'

export default function* rootSaga() {
  yield all(
    combineWatchers(
      AppWatcher,
      AdminWatcher,
      CommodityProductsWatcher,
      UtilitiesWatcher,
      WeatherWatcher
    )
  )
}
