import { useNavigate, useLocation } from "react-router-dom";
import { useState } from "react";
import Layout from "./layout";


export default function Page({
    pageName, 
    Component,
	componentProps={},
    sideBarRoutes=[],
	prevPage={ to: '/', text: 'Back to Home' },	
	addButtonObj,
	filterFields=[],
	widescreen=false
}){
	const navigate = useNavigate()
	const location = useLocation()

	const [canEdit, setCanEdit] = useState(false)
	const [showModal, setShowModal] = useState(false)

	const cancelModal = () => setShowModal(false)

	const actions = [
		{
			onClick: () => setShowModal(true),
			children: 'Add ' + (addButtonObj ? addButtonObj : pageName),
			disabled: !canEdit
		}
	]

	const containerClassNames = ['moduleContainer']

	if (!widescreen) containerClassNames.push("moduleContainerGutter")

	return <Layout 
		header={pageName}
		sideBarProps={{
			navProps: {
				routes: sideBarRoutes.map(sbr => ({
					title: sbr.title,
					onClick: () => sbr.onClick ? sbr.onClick() : navigate(sbr.route),
					isSelected: sbr.selected ? sbr.selected : location.pathname === sbr.route
				}))
			},
			prevPage: prevPage
		}}
		actions={actions.filter(a => !a.disabled)}
		filterFields={filterFields}
	>
		<div className={containerClassNames.join(' ')}>
			<Component 
				canEdit={canEdit}
				setCanEdit={setCanEdit} 
				showModal={showModal} 
				cancelModal={cancelModal}
				{...componentProps}
			/>
		</div>
	</Layout>
}
