import './spinner.scss'

const SPINNER_SIZES = {
    's': 'spinnerSizeS',
    'm': 'spinnerSizeM',
    'l': 'spinnerSizeL'
}


const Spinner = ({ size = 's', text=null }) => {
    return <div className="spinnerContainer flex-column justifyCenter alignCenter">
        <div className={["spinner", SPINNER_SIZES[size.toLowerCase()]].join(' ')}></div>
        <div className='spinnerText'>{text}</div>
    </div>
}

export default Spinner
