import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { CommodityProductActions } from "../../../redux/actions"
import { Icons, MarketDataPrivileges } from "../../../lib/constants"
import { Form, Modal, DataCard, DeleteModal } from '../../../components'


const DataSourceForm = ({ dataSource={}, canEdit, handleSubmit, cancelEdit }) => {
    const [values, setValues] = useState(dataSource)
    const [errors, setErrors] = useState({})
   

    const fields = [
        {
            fieldName: 'code',
            value: values.code,
            label: 'Code',
            readOnly: !!values.id,
            onChange: ({target: {value}}) => setValues({...values, code: value})
        },
        {
            fieldName: 'description',
            value: values.description,
            label: 'Name',
            readOnly: !canEdit,
            onChange: ({target: {value}}) => setValues({...values, description: value})
        }
    ]

    const onSubmit = e => {
        e.preventDefault();
        let errObj = {}

        if (!values.code) errObj = {...errObj, code: 'Enter valid code'}
        if (!values.description) errObj = {...errObj, description: 'Enter valid name'}

        if (Object.keys(errObj).length > 0) {
            setErrors(errObj)
            return
        }

        handleSubmit(values)
        
        cancelEdit(e)
    }

    const formActions = [
        { buttonType: 'secondaryButton', text: 'Cancel', onClick: cancelEdit},
        { text: values.id ? 'Update': 'Create', type: 'submit'}
    ]

    return <Form  fields={fields} errors={errors} onSubmit={onSubmit} actions={formActions} canEdit={canEdit} />
}

const DataSourceCard = ({ dataSource, canEdit, dispatch }) => {
    const [deleteMode, setDeleteMode] = useState(false)

    const handleSubmit = ds => dispatch(CommodityProductActions.DataSources.updateDataSource(ds))

    return <><DataCard 
        canEdit={canEdit} 
        title={dataSource['code']} 
        subtitle={dataSource['description']}
        actions={[
            {icon: Icons.DELETE, handler: () => setDeleteMode(true), canEdit: !canEdit}
        ]}
    >
        <DataSourceForm dataSource={dataSource} canEdit={canEdit} handleSubmit={handleSubmit}/>
    </DataCard>
    <DeleteModal 
        deleteMode={deleteMode}
        setDeleteMode={setDeleteMode}
        onDelete={() => {
            dispatch(CommodityProductActions.DataSources.deleteDataSource(dataSource.id))
            setDeleteMode(false)
        }}
        warningMessage={`Are you sure you want to delete data source ${dataSource['code']}? This 
        will delete all commodity product records with this record as data source`}
    />
    </>
}

export default function Module ({ canEdit, setCanEdit, showModal, cancelModal }){
    const dataSources = useSelector(state => state.commodityProducts.dataSources)
    const me = useSelector(state => state.app.me)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        if(!!me){
            let authorized = me.claims.filter(c => c.name === MarketDataPrivileges.VIEW_COMMODITY_PRODUCTS).length > 0

            if (!authorized) navigate('/')

            else{
                setCanEdit(me.claims.filter(c => c.name === MarketDataPrivileges.CREATE_EDIT_COMMODITY_PRODUCTS).length > 0)

                if (!dataSources) dispatch(CommodityProductActions.DataSources.getDataSources())
            }
        }
    })

    return <>
        <div className="moduleContainer">
            {(dataSources || []).map((ds, d) => <DataSourceCard dataSource={ds} key={d} dispatch={dispatch} canEdit={canEdit}/>)}
        </div>
        <Modal 
            title='Add Data Source'
            show={showModal}
            cancel={cancelModal}
        >
            <DataSourceForm 
                canEdit={canEdit} 
                cancelEdit={cancelModal} 
                handleSubmit={product => dispatch(CommodityProductActions.DataSources.createDataSource(product))} 
            />
        </Modal>
    </>
}