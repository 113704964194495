export const types = {
    // Utilities
    GET_UTILITIES: "GET_UTILITIES",
    SAVE_UTILITIES: "SAVE_UTILITIES",

    CREATE_UTILITY: "CREATE_UTILITY",
    DELETE_UTILITY: "DELETE_UTILITIES",
    UPDATE_UTILITY: "UPDATE_UTILITY",

    // Rate classes
    GET_RATE_CLASSES: "GET_RATE_CLASSES",
    SAVE_RATE_CLASSES: "SAVE_RATE_CLASSES",

    CREATE_RATE_CLASS: "CREATE_RATE_CLASS",
    DELETE_RATE_CLASS: "DELETE_RATE_CLASS",
    UPDATE_RATE_CLASS: "UPDATE_RATE_CLASS",

    // Load profiles
    GET_LOAD_PROFILES: "GET_LOAD_PROFILES",
    SAVE_LOAD_PROFILES: "SAVE_LOAD_PROFILES",

    CREATE_LOAD_PROFILES: "CREATE_LOAD_PROFILES",
    DELETE_LOAD_PROFILES: "DELETE_LOAD_PROFILES",
    UPDATE_LOAD_PROFILES: "UPDATE_LOAD_PROFILES",

    // Schedules
    GET_METER_READ_SCHEDULES: "GET_METER_READ_SCHEDULES",
    SAVE_METER_READ_SCHEDULES: "SAVE_METER_READ_SCHEDULES",

    CREATE_METER_READ_SCHEDULE: "CREATE_METER_READ_SCHEDULE",
    DELETE_METER_READ_SCHEDULE: "DELETE_METER_READ_SCHEDULE",
    UPDATE_METER_READ_SCHEDULE: "UPDATE_METER_READ_SCHEDULE",
    
}

// Utilities
export const getUtilities = () => ({type: types.GET_UTILITIES})

export const saveUtilities = payload => ({type: types.SAVE_UTILITIES, payload})

export const createUtility = payload => ({type: types.CREATE_UTILITY, payload})

export const updateUtility = payload => ({type: types.UPDATE_UTILITY, payload})

export const deleteUtility = utilityId => ({type: types.DELETE_UTILITY, payload: utilityId})

// Rate classes
export const getRateClasses = utilityId => ({type: types.GET_RATE_CLASSES, payload: utilityId})

export const saveRateClasses = payload => ({type: types.SAVE_RATE_CLASSES, payload})

export const createRateClass = payload => ({type: types.CREATE_RATE_CLASS, payload})

export const updateRateClass = payload => ({type: types.UPDATE_RATE_CLASS, payload})

export const deleteRateClass = payload => ({type: types.DELETE_RATE_CLASS, payload})

// Load profiles
export const getLoadProfiles = utilityId => ({type: types.GET_LOAD_PROFILES, payload: utilityId});

export const saveLoadProfiles = payload => ({type: types.SAVE_LOAD_PROFILES, payload});

export const createLoadProfile = payload => ({type: types.CREATE_LOAD_PROFILES, payload});

export const updateLoadProfile = payload => ({type: types.UPDATE_LOAD_PROFILES, payload});

export const deleteLoadProfile = payload => ({type: types.DELETE_LOAD_PROFILES, payload});

// Meter read schedules
export const getMeterReadSchedules = utilityId => ({type: types.GET_METER_READ_SCHEDULES, payload: utilityId});

export const saveMeterReadSchedules = payload => ({type: types.SAVE_METER_READ_SCHEDULES, payload});

export const createMeterReadSchedule = payload => ({type: types.CREATE_METER_READ_SCHEDULE, payload});

export const updateMeterReadSchedule = payload => ({type: types.UPDATE_METER_READ_SCHEDULE, payload});

export const deleteMeterReadSchedule = payload => ({type: types.DELETE_METER_READ_SCHEDULE, payload});
