import React, { useState } from "react"
import './dataCard.scss'


const DataCard = ({ canEdit, title, subtitle, expand=true, actions=[], children, id}) => {   
    const [editMode, setEditMode] = useState(false)

    const toggleEdit = () => expand ? setEditMode(!editMode) : null

    const cardClassNames = ['dataCardContainer', 'cardBorders']

    if (!editMode) cardClassNames.push('collapsed')

    return <div className={cardClassNames.join(' ')} id={id}>
        <div className="dccHeader flex cursor-pointer" onClick={toggleEdit}>
            <div className="dcchText">
                <h2>{title}</h2>
                {subtitle && <h3>{subtitle}</h3>}
            </div>
            <div className="dcchActions flex">
                {actions.filter(act => !act.canEdit || canEdit).map((action, a) =>
                    <div 
                        key={a}
                        className="dcchaAction"
                        onClick={(e) => {
                            e.stopPropagation();
                            action.handler(e, action)
                        }}
                    >
                        {action.icon}
                    </div>
                )}
            </div>
        </div>
        {editMode && children && <div className="dccExpand">
            <div className="dccExpandDivide"/>
            {React.cloneElement(children, {cancelEdit: toggleEdit})}
        </div>}
    </div>
}

export default DataCard