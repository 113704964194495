import { Utilities, Utility } from '../../modules/market/utilities'

const WeatherRoutes = {
    path: 'utilities',
    element: <Utilities />,
    subRoutes: [
        {
            path: ':id',
            component: Utility
        }
    ]
}

export default WeatherRoutes