const months = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec'
}


const formatDate = date => {
    let year = date.getFullYear()
    let month = date.getMonth() + 1
    let day = date.getDate()
    let hour = date.getHours()
    let minutes = date.getMinutes()

    let am = 'AM'

    let date_fmt = months[month] + ' ' + day + ', ' + year

    if (minutes === 0 && hour === 0) return date_fmt

    if (hour > 12) {
        hour = hour - 12
        am = 'PM'
    }

    else if (hour === 12) am = 'PM'

    if (minutes === 0) return date_fmt + ' ' + hour + ' ' + am

    else return date_fmt + ' ' + hour + ":" + minutes + ' ' + am
}

export default formatDate